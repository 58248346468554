import { ReducerType } from './type'

const InitialState = {
  events: [],
  isEvents: false,
  calenders: [],
  isCalenders: false,
  savedCalenders: [],
  savedPipleline: null,
  isUserStages: false,
  userStages: []
}

const calenders = (state = InitialState, action) => {
  switch (action?.type) {
    case ReducerType.GET_ALL_EVENTS_START:
      return {
        ...state,
        isEvents: true
      }

    case ReducerType.GET_ALL_EVENTS:
      return {
        ...state,
        isEvents: false,
        events: action.payload
      }
    case ReducerType.GET_ALL_EVENTS_ERROR:
      return {
        ...state,
        isEvents: false,
        events: []
      }

    case ReducerType.GET_ALL_CALENDERS_START:
      return {
        ...state,
        isCalenders: true
      }

    case ReducerType.GET_ALL_CALENDERS:
      return {
        ...state,
        isCalenders: false,
        calenders: action.payload
      }
    case ReducerType.GET_ALL_CALENDERS_ERROR:
      return {
        ...state,
        isCalenders: false,
        calenders: []
      }
    case ReducerType.GET_SAVED_CALENDERS:
      return {
        ...state,
        savedCalenders: action.payload
      }

    case ReducerType.GET_SAVED_PIPELINE:
      return {
        ...state,
        savedPipleline: action.payload
      }

    case ReducerType.GET_ALL_USER_STAGES_START:
      return {
        ...state,
        isUserStages: true
      }

    case ReducerType.GET_USER_STAGES:
      return {
        ...state,
        userStages: action.payload,
        isUserStages: false
      }
    case ReducerType.GET_ALL_USER_STAGES_ERROR:
      return {
        ...state,
        userStages: [],
        isUserStages: false
      }

    default:
      return state
  }
}
export default calenders
