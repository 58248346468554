import React, { useEffect, useState } from 'react'
import Projects from './Porjects'
import { Row, Col, Card, Spinner } from 'react-bootstrap'
import TotalUsers from './TotalUsers'
import TotalClients from './TotalClients'
import Settings from './Settings'
import { getAllOpportunities } from '../../../redux/projects/action'
import { getAllUsers } from '../../../redux/users/action'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { getAllProjectsCount } from '../../../redux/dashboard/action'
const Dashboard = () => {
  const dispatch = useDispatch()
  const { pipelines, isPipelines } = useSelector(state => state.projects)
  const { isProjectsCount, projectsCount } = useSelector(state => state.dashboard)
  const { userDetails } = useSelector(state => state.login)
  const { savedPipleline } = useSelector(state => state.calenders)
  let selectedPipeline = pipelines?.find(item => item?.id === savedPipleline?.pipelineId)
  const [stagesObject, setStagesObject] = useState(null)

  useEffect(() => {
    if (selectedPipeline) {
      dispatch(getAllProjectsCount({ pipelineId: selectedPipeline?.id }))
    }
  }, [selectedPipeline])

  useEffect(() => {
    if (savedPipleline && userDetails) {
      dispatch(getAllUsers())
      const fetchDataForPipelines = async () => {
        try {
          const promises = selectedPipeline?.stages?.map(stage => {
            dispatch(
              getAllOpportunities({ pipeline_id: savedPipleline?.pipelineId, limit: 20, pipeline_stage_id: stage?.id })
            )
          })

          await Promise.all(promises)
        } catch (error) {
          console.error('Dashboard Error fetching data:', error)
        }
      }
      fetchDataForPipelines()
    }
  }, [savedPipleline, selectedPipeline, userDetails])
  useEffect(() => {
    if (projectsCount && selectedPipeline) {
      const updatedStages = selectedPipeline?.stages?.map(stage => {
        const filteredProject = projectsCount?.projectCount?.find(count => count?.id === stage?.id)
        return {
          ...stage,
          total: filteredProject ? filteredProject?.count : 0
        }
      })
      const updatedPipeline = {
        ...selectedPipeline,
        stages: updatedStages
      }

      setStagesObject(updatedPipeline)
    }
  }, [projectsCount, selectedPipeline])

  return (
    <>
      <Card className='p-4'>
        {isPipelines ? (
          <div className='d-flex justify-content-center'>
            <Spinner />
          </div>
        ) : (
          <Row className='g-3 mb-3'>
            <Col md={4} sm={6} xxl={3}>
              <Projects Opportunities={stagesObject} isProjectsCount={isProjectsCount} />
            </Col>
            <Col md={4} sm={6} xxl={3}>
              <TotalUsers users={projectsCount?.user} isProjectsCount={isProjectsCount} />
            </Col>
            <Col md={4} sm={6} xxl={3}>
              <TotalClients isProjectsCount={isProjectsCount} clients={projectsCount?.client} />
            </Col>
            <Col md={4} sm={6} xxl={3}>
              <Settings radius={['100%', '87%']} />
            </Col>
          </Row>
        )}
      </Card>
    </>
  )
}

export default Dashboard
