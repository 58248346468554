import { checkResponse, get, post, put } from '../../utils/constants'
import { ReducerType } from './type'
import { apis } from '../../utils/Apis'

export const getAllNotifications = (params, type) => async dispatch => {
  dispatch({ type: ReducerType.GET_ALL_NOTIFICATION_ACTION_START })

  const res = await get(apis.get_all_notifications, params)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_ALL_NOTIFICATION,
      payload: response.data?.data
    })

    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.GET_ALL_NOTIFICATION_ERROR
    })
  }
}
export const getLatestNotifications = (params, type) => async dispatch => {
  dispatch({ type: ReducerType.GET_ALL_NOTIFICATION_ACTION_START })

  const res = await get(apis.get_all_notifications, params)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_LATEST_NOTIFICATION,
      payload: response.data?.data
    })

    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.GET_ALL_NOTIFICATION_ERROR
    })
  }
}

export const getOldNotifications = (params, type) => async dispatch => {
  dispatch({ type: ReducerType.GET_OLD_NOTIFICATIONS_START })

  const res = await get(apis.get_all_notifications, params)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_OLD_NOTIFICATIONS,
      payload: response.data?.data
    })

    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.GET_OLD_NOTIFICATIONS_ERROR
    })
  }
}

export const readNotification = async data => {
  const res = await put(apis.read_notifications, data)
  return res
}
export const getAllNotificationsSettings = async params => {
  const res = await get(apis.notification_settings, params)
  return res
}
export const editNotificationsSettings = async data => {
  const res = await put(apis.notification_settings_edit, data)
  return res
}
