import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, Button, Form, Spinner } from 'react-bootstrap'
import warningIcon from '../../../../assets/warning.svg'
import { toast } from 'react-toastify'
import { editGlobalNotificationsSettings } from '../../../../redux/setting/action'

const NotificationModal = ({
  openNotificationModal,
  handleCloseModal,
  notificationsSettings,
  allowNotificationStages,
  disableAll
}) => {
  const [applyLoader, setApplyLoader] = useState(false)

  const handleApplyGlobal = async () => {
    setApplyLoader(true)
    try {
      const params = {
        assign: notificationsSettings?.assign,
        bugFix: notificationsSettings?.bugFix,
        bugRaise: notificationsSettings?.bugRaise,
        clientDocumentAdd: notificationsSettings?.clientDocumentAdd,
        clientDocumentDelete: notificationsSettings?.clientDocumentDelete,
        documentAdd: notificationsSettings?.documentAdd,
        documentDelete: notificationsSettings?.documentDelete,
        noteAdd: notificationsSettings?.noteAdd,
        noteDelete: notificationsSettings?.noteDelete,
        clientChat: notificationsSettings?.clientChat,
        teamChat: notificationsSettings?.teamChat,
        stageUpdate: 1,
        unassign: notificationsSettings?.unassign,
        videoAdd: notificationsSettings?.videoAdd,
        videoDelete: notificationsSettings?.videoDelete,
        muteAll: disableAll ? 1 : 0,
        allowNotificationStages: allowNotificationStages
      }
      let response = await editGlobalNotificationsSettings(params)
      if (response?.data?.status_code == 201 || response?.data?.status_code == 200) {
        toast.success(response?.data?.message, {
          position: 'top-right',
          theme: 'colored'
        })
      } else {
        toast.error(response?.data?.message, {
          position: 'top-right',
          theme: 'colored'
        })
      }
    } catch (err) {
      console.error('Error updating notifications:', err)
    } finally {
      setApplyLoader(false)
      handleCloseModal()
    }
  }

  return (
    <Modal
      show={openNotificationModal}
      onHide={handleCloseModal}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      style={{
        border: 'none'
      }}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body
        style={{
          height: 'auto',
          overflow: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '10px'
        }}
      >
        <img src={warningIcon} alt='dmd' style={{ width: '64px', height: '64px' }} />
        <p style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600' }}> Confirm Global Application</p>
        <p style={{ textAlign: 'center', fontSize: '13px', fontWeight: '400', color: 'rgba(131, 130, 137, 1)' }}>
          Are you sure you want to apply these settings to all projects? This action will override existing settings.
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            gap: '10px'
          }}
        >
          <Button variant='secondary' onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant='primary' onClick={handleApplyGlobal} disabled={applyLoader}>
            {applyLoader ? <Spinner size='sm' /> : 'Apply'}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default NotificationModal
