export const ReducerType = {
  // basic Details
  GET_ALL_EVENTS_START: 'GET_ALL_EVENTS_START',
  GET_ALL_EVENTS: 'GET_ALL_EVENTS',
  GET_ALL_EVENTS_ERROR: 'GET_ALL_EVENTS_ERROR',

  GET_ALL_CALENDERS_START: 'GET_ALL_CALENDERS_START',
  GET_ALL_CALENDERS: 'GET_ALL_CALENDERS',
  GET_ALL_CALENDERS_ERROR: 'GET_ALL_CALENDERS_ERROR',

  GET_SAVED_CALENDERS: 'GET_SAVED_CALENDERS',

  GET_SAVED_PIPELINE: 'GET_SAVED_PIPELINE',
  GET_ALL_USER_STAGES_START: 'GET_ALL_USER_STAGES_START',
  GET_USER_STAGES: 'GET_USER_STAGES',
  GET_ALL_USER_STAGES_ERROR: 'GET_ALL_USER_STAGES_ERROR'
}
