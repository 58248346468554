import { kanbanItems } from 'data/kanban'
import { ReducerType } from './type'
import _ from 'lodash'

const initData = {
  kanbanItems: kanbanItems,
  pipelines: [],
  Opportunities: [],
  isOpportunities: false,
  isDevJobs: true,
  DevJobs: [],
  OpportunitiesAllStagesArr: [],
  isStageOpportunities: false,
  isPipelines: false,
  isProjectSettings: false,
  PorjectSettings: null,
  allProjectSettings: [],
  isNotes: false,
  Notes: [],
  selectedPipeline: null,
  isCardMoving: false,
  cardMovingId: '',
  kanbanModal: {
    show: false,
    modalContent: {}
  },

  openDetailModal: {
    show: false,
    title: ''
  },
  isProjectBugsList: false,
  projectBugsList: []
}
const projects = (state = initData, action) => {
  const { type, payload } = action
  switch (type) {
    // Pipelines=================>

    case ReducerType.SELECTED_PIPELINE:
      return {
        ...state,
        selectedPipeline: action.payload
      }

    case ReducerType.GET_ALL_PIPELINES_START:
      return {
        ...state,
        isPipelines: true
      }
    case ReducerType.GET_ALL_PIPELINES:
      return {
        ...state,
        pipelines: payload,
        isPipelines: false
      }
    case ReducerType.GET_ALL_PIPELINES_ERROR:
      return {
        ...state,
        pipelines: [],
        isPipelines: false
      }
    //Opportunities=========>

    case ReducerType.GET_ALL_OPPORTUNITIES_START:
      return {
        ...state,
        OpportunitiesAllStagesArr: [],
        isOpportunities: true
      }

    case ReducerType.GET_ALL_OPPORTUNITIES:
      const updatedOpportunitiesAllStagesArr = _.uniqBy([...state?.OpportunitiesAllStagesArr, payload], item => {
        if (item && item?.opportunities && item?.opportunities?.length > 0) {
          return item?.opportunities[0]?.id
        }
        return undefined
      })
      return {
        ...state,
        OpportunitiesAllStagesArr: updatedOpportunitiesAllStagesArr
      }

    case ReducerType.MODIFIED_OPPORTUNITIES_ARRAY:
      // Concatenate the opportunities array with the merged array
      let mergedOpportunities = []
      // Iterate over each object in dataArray and merge opportunities
      state.OpportunitiesAllStagesArr.forEach(obj => {
        mergedOpportunities = mergedOpportunities?.concat(obj?.opportunities)
      })
      let filteredHeaders =
        state?.pipelines.length > 0
          ? state?.pipelines.find(pipeline => pipeline?.id === state?.selectedPipeline?.pipelineId)
          : null
      if (filteredHeaders && filteredHeaders?.stages) {
        // const additionalStateObject = {
        //   id: '1d7133de-5b1c-4167-8bf1-aefcf-7005320',
        //   name: 'Archived',
        //   position: 99999,
        //   showInFunnel: true,
        //   showInPieChart: true,
        //   headOpportunities: []
        // }

        const updatedStages = filteredHeaders?.stages?.map(stage => ({
          ...stage,
          headOpportunities: []
        }))

        // updatedStages.push(additionalStateObject)

        _.forEach(mergedOpportunities, obj => {
          const matchedIndex = updatedStages?.findIndex(stage => stage?.id === obj?.pipelineStageId)
          if (matchedIndex !== -1) {
            // if (obj.status !== 'complete' && obj.status !== 'won') {
            updatedStages[matchedIndex] = {
              ...updatedStages[matchedIndex],
              headOpportunities: [...updatedStages[matchedIndex]?.headOpportunities, obj]
              //   }
              // } else {
              //   updatedStages[updatedStages.length - 1] = {
              //     ...updatedStages[updatedStages.length - 1],
              //     headOpportunities: [...updatedStages[updatedStages.length - 1].headOpportunities, obj]
              //   }
            }
          }
        })

        filteredHeaders = { ...filteredHeaders, stages: updatedStages }
      }
      filteredHeaders?.stages.forEach(item => {
        item.headOpportunities = _.uniqBy(item?.headOpportunities, 'id')
      })

      return {
        ...state,
        Opportunities: filteredHeaders?.stages,
        isOpportunities: false
      }

    case ReducerType.GET_OLDER_PROJECTS_OF_STAGE_START:
      return {
        ...state,
        isStageOpportunities: true
      }

    case ReducerType.GET_OLDER_PROJECTS_OF_STAGE:
      const { meta, opportunities } = payload
      // Create a new array of updated stages without mutating the original state
      const updatedStages = state?.OpportunitiesAllStagesArr?.map(stage => {
        if (stage?.meta?.pipeline_stage_id === meta?.pipeline_stage_id) {
          // Merge opportunities array while ensuring uniqueness based on id
          const mergedOpportunities = [
            ...stage?.opportunities,
            ...opportunities?.filter(
              newOpportunity => !stage?.opportunities?.some(oldOpportunity => oldOpportunity?.id === newOpportunity?.id)
            )
          ]
          // Create a new stage object with updated opportunities and meta
          return {
            ...stage,
            opportunities: mergedOpportunities,
            meta: meta
          }
        }
        return stage // Return the unchanged stage if it doesn't match the specified pipeline stage ID
      })

      return {
        ...state,
        OpportunitiesAllStagesArr: updatedStages,
        isStageOpportunities: false
      }
    case ReducerType.GET_OLDER_PROJECTS_OF_STAGE_ERROR:
      return {
        ...state,
        isStageOpportunities: false
      }

    case ReducerType.GET_ALL_OPPORTUNITIES_ERROR:
      return {
        ...state,
        Opportunities: [],
        isOpportunities: false
      }
    //===============

    case ReducerType.OPEN_KANBAN_MODAL:
      return {
        ...state,
        kanbanModal: {
          ...state.kanbanModal,
          modalContent: {
            ...state.kanbanModal.modalContent,
            image: payload.image
          },
          show: true
        }
      }

    case ReducerType.TOGGLE_KANBAN_MODAL:
      return {
        ...state,
        kanbanModal: {
          ...state.kanbanModal,
          show: !state.kanbanModal.show
        }
      }

    case ReducerType.ADD_KANBAN_COLUMN:
      return {
        ...state,
        kanbanItems: [...state.kanbanItems, payload]
      }

    case ReducerType.ADD_TASK_CARD:
      return {
        ...state,
        kanbanItems: state?.kanbanItems?.map(kanbanItem =>
          kanbanItem.id === payload?.targetListId
            ? { ...kanbanItem, items: [...kanbanItem?.items, payload?.newCard] }
            : kanbanItem
        )
      }

    case ReducerType.UPDATE_SINGLE_COLUMN:
      return {
        ...state,
        Opportunities: state?.Opportunities?.map(kanbanItem =>
          kanbanItem.id === payload?.column?.id
            ? {
                ...kanbanItem,
                headOpportunities: payload?.reorderedItems?.length > 0 ? [...payload.reorderedItems] : []
              }
            : kanbanItem
        )
      }
    case ReducerType.IS_CARD_MOVING_START:
      return {
        ...state,
        isCardMoving: true,
        cardMovingId: payload
      }
    case ReducerType.IS_CARD_MOVING:
      return {
        ...state,
        isCardMoving: false,
        cardMovingId: ''
      }
    case ReducerType.IS_CARD_MOVING_ERROR:
      return {
        ...state,
        isCardMoving: false,
        cardMovingId: ''
      }

    case ReducerType.UPDATE_DUAL_COLUMN:
      const { updatedDestItems, destColumn, sourceColumn, updatedSourceItems } = action?.payload
      const updatedDestItemIds = new Set(updatedDestItems.map(item => item?.id))

      // Logic to update opportunities array without mutating the state
      const updatedOpportunitiesAllStages = state?.OpportunitiesAllStagesArr?.map(item => {
        if (item.meta.pipeline_stage_id === sourceColumn?.id) {
          const updatedOpportunities = item?.opportunities?.map(opportunity => {
            if (updatedDestItemIds?.has(opportunity?.id)) {
              return {
                ...opportunity,
                pipelineStageId: destColumn?.id,
                pipelineStageUId: destColumn?.id
              }
            }
            return opportunity
          })
          return {
            ...item,
            opportunities: updatedOpportunities
          }
        }
        return item
      })

      const newArr = updatedDestItems?.map(item => ({
        ...item,
        pipelineStageId: destColumn?.id,
        pipelineStageUId: destColumn?.id
      }))

      return {
        ...state,
        Opportunities: state?.Opportunities?.map(kanbanItem => {
          if (kanbanItem?.id === sourceColumn?.id) {
            return {
              ...kanbanItem,
              headOpportunities: updatedSourceItems
            }
          } else if (kanbanItem?.id === destColumn?.id) {
            return {
              ...kanbanItem,
              headOpportunities: newArr
            }
          }
          return kanbanItem
        }),
        OpportunitiesAllStagesArr: updatedOpportunitiesAllStages
      }

    case ReducerType.DEV_UPDATE_DUAL_COLUMN:
      const updatedDevDestItemIds = action.payload?.updatedDestItems?.map(item => item?.id)
      const updatedDevOpportunitiesAllStages = state?.DevJobs?.map(item => {
        // Check if the item matches the condition to be updated
        if (updatedDevDestItemIds.includes(item?.id)) {
          return {
            ...item,
            pipelineStageId: action?.payload?.destColumn?.id,
            pipelineStageUId: action?.payload?.destColumn?.id
          }
        }
        // Keep the item unchanged if it doesn't match the condition
        return item
      })
      return {
        ...state,
        DevJobs: updatedDevOpportunitiesAllStages
      }

    case ReducerType.REMOVE_KANBAN_COLUMN:
      return {
        ...state,
        kanbanItems: state?.kanbanItems?.filter(kanbanItem => kanbanItem?.id !== payload?.id)
      }

    case ReducerType.REMOVE_TASK_CARD:
      return {
        ...state,
        kanbanItems: state?.Opportunities?.map(kanbanItem => {
          return {
            ...kanbanItem,
            headOpportunities: kanbanItem?.items?.filter(item => item?.id !== payload?.id)
          }
        })
      }

    case ReducerType.REMOVE_ASSIGNED_CARD:
      return {
        ...state,
        Opportunities: state?.Opportunities?.map(kanbanItem =>
          kanbanItem?.id === payload?.pipelineId
            ? {
                ...kanbanItem,
                headOpportunities: kanbanItem?.headOpportunities?.filter(item => item?.id !== payload?.id)
              }
            : kanbanItem
        )
      }

    case ReducerType.MOVE_TO_INPROGRESS:
      return {
        ...state,
        Opportunities: state.Opportunities.map(kanbanItem =>
          kanbanItem?.id === payload?.id
            ? {
                ...kanbanItem,
                headOpportunities: [...kanbanItem.headOpportunities, payload?.destColumns]
              }
            : kanbanItem
        )
      }

    //dev jobs

    case ReducerType.GET_DEV_JOBS_START:
      return {
        ...state,
        isDevJobs: true,
        DevJobs: []
      }

    case ReducerType.GET_DEV_JOBS:
      return {
        ...state,
        isDevJobs: false,
        DevJobs: action.payload?.opportunities
      }

    case ReducerType.ASSIGN_TO_DEV:
      const { id, assignToId } = action.payload
      const updatedDevJobs = state?.DevJobs?.map(job => {
        if (job.id === id) {
          return {
            ...job,
            assignedTo: assignToId
          }
        }
        return job
      })
      return {
        ...state,
        DevJobs: updatedDevJobs
      }

    case ReducerType.GET_DEV_JOBS_ERROR:
      return {
        ...state,
        isDevJobs: false,
        DevJobs: []
      }
    //project settings detail api
    case ReducerType.GET_PROJECT_SETTINGS_DETAIL_START:
      return {
        ...state,
        isProjectSettings: true,
        PorjectSettings: null
      }
    case ReducerType.GET_PROJECT_SETTINGS_DETAIL:
      return {
        ...state,
        isProjectSettings: false,
        PorjectSettings: action.payload
      }
    case ReducerType.GET_PROJECT_SETTINGS_DETAIL_ERROR:
      return {
        ...state,
        isProjectSettings: false,
        PorjectSettings: null
      }
    case ReducerType.GET_PROJECT_SETTINGS_ALL:
      return {
        ...state,
        allProjectSettings: action.payload
      }

    case ReducerType.TOGGLE_DETAIL_MODAL:
      return {
        ...state,
        openDetailModal: {
          show: action.payload.show,
          title: action.payload.title
        }
      }

    case ReducerType.REMOVE_ALL_PROJECTS_SETTINGS:
      return {
        ...state,
        kanbanItems: kanbanItems,
        pipelines: [],
        Opportunities: [],
        isOpportunities: false,
        isDevJobs: false,
        DevJobs: [],
        isPipelines: false,
        isProjectSettings: false,
        PorjectSettings: null,
        allProjectSettings: [],
        isNotes: false,
        Notes: [],
        selectedPipeline: null,
        kanbanModal: {
          show: false,
          modalContent: {}
        }
      }

    case 'REMOVE_ALL_OPPORTUNITIES':
      return {
        ...state,
        OpportunitiesAllStagesArr: [],
        Opportunities: []
      }
    case ReducerType.DELETE_OPPORTINITY:
      const updatedOpportunities =
        state.Opportunities?.map(item => {
          if (item?.id === action?.payload?.pipelineStageId) {
            const filteredHeadOpportunities =
              item.headOpportunities?.filter(headOpportunity => headOpportunity?.id !== action?.payload?.id) ?? []
            return { ...item, headOpportunities: filteredHeadOpportunities }
          }
          return item
        }) ?? []
      const updatedOpportunitiesIntialArr =
        state.OpportunitiesAllStagesArr?.map(item => {
          if (item?.meta?.pipeline_stage_id === action?.payload?.pipelineStageId) {
            const filteredHeadOpportunities =
              item.opportunities?.filter(headOpportunity => headOpportunity?.id !== action?.payload?.id) ?? []
            return { ...item, opportunities: filteredHeadOpportunities }
          }
          return item
        }) ?? []
      let newDevJobs = state?.DevJobs?.filter(item => item?.id !== payload?.id)
      return {
        ...state,
        OpportunitiesAllStagesArr: updatedOpportunitiesIntialArr,
        Opportunities: updatedOpportunities,
        DevJobs: newDevJobs
      }

    case ReducerType.GET_PROJECT_BUGS_LIST_START:
      return {
        ...state,
        isProjectBugsList: true
      }

    case ReducerType.GET_PROJECT_BUGS_LIST:
      return {
        ...state,
        projectBugsList: action.payload ?? [],
        isProjectBugsList: false
      }

    case ReducerType.GET_PROJECT_BUGS_LIST_ERROR:
      return {
        ...state,
        projectBugsList: [],
        isProjectBugsList: false
      }

    case ReducerType.OPPORTUNITIES_STAGE_UPDATE:
      let matchedOpportunity = null
      let matchedOpportunityAllState = null
      let findSetting = state.allProjectSettings?.find(item => item?.projectId == payload?.id)
      const result = findSetting?.skillsDetails?.some(skill => action?.userDetail?.skills?.includes(skill?._id))

      const filterOpportunities = state?.Opportunities?.map(item => {
        const foundOpportunity = item?.headOpportunities?.find(headOpportunity => headOpportunity?.id === payload?.id)
        if (foundOpportunity) {
          let modifedObj = { ...foundOpportunity }
          modifedObj.pipelineStageId = payload.pipelineStageId
          modifedObj.pipelineStageUId = payload.pipelineStageUId
          matchedOpportunity = modifedObj
        }

        const filteredHeadOpportunities =
          item.headOpportunities?.filter(headOpportunity => headOpportunity?.id !== payload?.id) ?? []
        return { ...item, headOpportunities: filteredHeadOpportunities }
      })

      const finalFilterOpportunities = filterOpportunities?.map(item => {
        if (item?.id === payload?.pipelineStageId) {
          const newHeadOpportunities = [...item.headOpportunities, matchedOpportunity]
          return { ...item, headOpportunities: newHeadOpportunities }
        }
        return item
      })

      ///update  OpportunitiesAllStagesArr======================>>
      const filterOpportunitiesAllState = state?.OpportunitiesAllStagesArr?.map(item => {
        const foundOpportunityAll = item?.opportunities?.find(headOpportunity => headOpportunity?.id === payload?.id)
        if (foundOpportunityAll) {
          let modifedObjBaseState = { ...foundOpportunityAll }
          modifedObjBaseState.pipelineStageId = payload?.pipelineStageId
          modifedObjBaseState.pipelineStageUId = payload?.pipelineStageUId
          matchedOpportunityAllState = modifedObjBaseState
        }
        const filteredHeadOpportunitiesAllState =
          item.opportunities?.filter(headOpportunity => headOpportunity?.id !== payload?.id) ?? []
        return { ...item, opportunities: filteredHeadOpportunitiesAllState }
      })

      const finalFilterOpportunitiesStateArr = filterOpportunitiesAllState?.map(item => {
        if (item?.meta?.pipeline_stage_id === payload?.pipelineStageId) {
          const newHeadOpportunitiesAllState = [...item.opportunities, matchedOpportunityAllState]
          return { ...item, opportunities: newHeadOpportunitiesAllState }
        }
        return item
      })
      //update for dev and client =========================>>

      let filterDevJobs = state?.DevJobs
      const isAssigned = findSetting?.assignments?.some(
        assignment => assignment?.assignee_id === action?.userDetail?.id
      )
      const isPayloadInArray = filterDevJobs?.some(item => item?.id === payload?.id)
      let selectedPipeline = state?.pipelines?.find(item => item?.id === action?.savedPipleline?.pipelineId)
      switch (action?.userDetail?.userType) {
        case 2:
          filterDevJobs = state?.DevJobs?.map(item => {
            if (item?.id === payload?.id && isAssigned) {
              return { ...item, pipelineStageId: payload?.pipelineStageId, pipelineStageUId: payload?.pipelineStageId }
            }

            return item
          })

          if (!isPayloadInArray && isAssigned) {
            filterDevJobs = [...filterDevJobs, payload]
          } else if (!isPayloadInArray && result) {
            // filterDevJobs = [...filterDevJobs, payload];
          }
          // else if (selectedPipeline?.stages[0]?.id === payload?.pipelineStageId) {
          //   let filteredArrDev = filterDevJobs?.filter((item) => item?.id !== payload?.id);
          //   filterDevJobs = filteredArrDev;

          // }
          else if (
            (selectedPipeline?.stages[1]?.id !== payload?.pipelineStageId ||
              selectedPipeline?.stages[0]?.id !== payload?.pipelineStageId) &&
            !isAssigned
          ) {
            let filteredArrDev = filterDevJobs?.filter(item => item?.id !== payload?.id)
            filterDevJobs = filteredArrDev
          }

          break
        case 3:
          filterDevJobs = state?.DevJobs?.map(item => {
            if (item?.id === payload?.id) {
              return { ...item, pipelineStageId: payload?.pipelineStageId, pipelineStageUId: payload?.pipelineStageId }
            }

            return item
          })
          if (
            selectedPipeline?.stages[0]?.id === payload?.pipelineStageId ||
            selectedPipeline?.stages[1]?.id === payload?.pipelineStageId
          ) {
            let filteredArr = filterDevJobs?.filter(item => item?.id !== payload?.id)
            filterDevJobs = filteredArr
          } else if (
            (selectedPipeline?.stages[0]?.id !== payload?.pipelineStageId ||
              selectedPipeline?.stages[1]?.id !== payload?.pipelineStageId) &&
            action?.userDetail?.id === payload?.contactId &&
            !isPayloadInArray
          ) {
            filterDevJobs = [...filterDevJobs, payload]
          }
          break
      }

      return {
        ...state,
        Opportunities: finalFilterOpportunities,
        OpportunitiesAllStagesArr: finalFilterOpportunitiesStateArr,
        DevJobs: filterDevJobs
      }

    //create opportunity====================>
    case ReducerType.OPPORTUNITIES_CREATE:
      // Find the indices of the stages
      let findStageIndex = state.Opportunities?.findIndex(item => item?.id === payload?.pipelineStageId)
      let findStageAllIndex = state?.OpportunitiesAllStagesArr?.findIndex(
        item => item?.meta?.pipeline_stage_id === payload?.pipelineStageId
      )

      // Create new opportunities array with the updated headOpportunities for the specific stage
      const newOpportunities = state?.Opportunities?.map((item, index) => {
        if (index === findStageIndex) {
          return {
            ...item,
            headOpportunities: [payload, ...item?.headOpportunities]
          }
        }
        return item
      })

      // Create new OpportunitiesAllStagesArr array with the updated headOpportunities for the specific stage
      const newOpportunitiesAllStagesArr = state?.OpportunitiesAllStagesArr?.map((item, index) => {
        if (index === findStageAllIndex) {
          return {
            ...item,
            opportunities: [payload, ...item?.opportunities]
          }
        }
        return item
      })
      return {
        ...state,
        Opportunities: newOpportunities,
        OpportunitiesAllStagesArr: newOpportunitiesAllStagesArr
      }

    default:
      return state
  }
}
export default projects
