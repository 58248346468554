import { combineReducers } from 'redux'

import login from './login/reducer'
import projects from './projects/reducer'
import users from './users/reducer'
import calenders from './calender/reducer'
import smsEmail from './smsMail/reducer'
import groupChat from './groupChat/reducer'
import client from './client/reducer'
import notifications from './notifications/reducer'
import dashboard from './dashboard/reducer'
import settings from './setting/reducer'

const rootReducer = combineReducers({
  login,
  projects,
  users,
  calenders,
  smsEmail,
  groupChat,
  client,
  notifications,
  dashboard,
  settings
})
export default rootReducer
