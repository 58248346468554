import FalconCardHeader from 'components/common/FalconCardHeader'
import { Col, Row, Spinner, Card, Button } from 'react-bootstrap'
import React, { useState, useEffect } from 'react'
import { notificationsArr, clientNotificationsArr } from 'utils/constants'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import NotificationModal from './notificationModal'
import { editNotificationsGlobalUpdate } from '../../../../redux/setting/action'

const GlobalNotification = () => {
  const { userDetails } = useSelector(state => state.login)
  const { userStages } = useSelector(state => state.calenders)
  const { isGlobalNotification, globalNotification } = useSelector(state => state.settings)
  const [intalSettings, setIntialSettings] = useState(notificationsArr)
  const [notificationsSettings, setNotificationsSettings] = useState(null)
  const [disableAll, setDisableAll] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [allowNotificationStages, setAllowNotificationStages] = useState([])
  const [filterPipeline, setFilterPipeline] = useState([])
  const [openNotificationModal, setOpenNotificationModal] = useState(false)

  useEffect(() => {
    const filterPipeline = userStages[0]?.stages?.map((item, index) => {
      let id = 1
      return {
        id: id + 1 + index,
        name: item.name,
        value: item.name,
        stageId: item?.id
      }
    })
    setFilterPipeline(filterPipeline)
    if (userDetails?.userType === 3 && filterPipeline) {
      const filterArr = clientNotificationsArr?.map(item => {
        if (item?.name == 'Project Stage Notifications') {
          return { ...item, child: [...item.child, ...filterPipeline] }
        } else {
          return { ...item }
        }
      })
      setIntialSettings(filterArr)
    } else {
      if (filterPipeline) {
        const filterArr = notificationsArr?.map(item => {
          if (item?.name == 'Project Stage Notifications') {
            return { ...item, child: [...item.child, ...filterPipeline] }
          } else {
            return { ...item }
          }
        })
        setIntialSettings(filterArr)
      }
    }
  }, [userDetails, userStages])

  useEffect(() => {
    if (filterPipeline) {
      let obj = {}
      filterPipeline?.forEach(item => {
        globalNotification?.allowNotificationStages?.forEach(stage => {
          if (item?.stageId == stage) {
            obj[item.name] = 1
            allowNotificationStages.push(stage)
          }
        })
      })
      setDisableAll(globalNotification?.muteAll == 1 ? true : false)
      setNotificationsSettings({ ...globalNotification, ...obj })
    }
  }, [filterPipeline])

  const handleCheckboxChange = (event, value) => {
    const stage = filterPipeline.find(stage => stage.name === value)

    if (stage) {
      if (event.target.checked) {
        if (!allowNotificationStages.includes(stage.stageId)) {
          allowNotificationStages.push(stage.stageId)
        }
      } else {
        const index = allowNotificationStages.indexOf(stage.stageId)
        if (index > -1) {
          allowNotificationStages.splice(index, 1)
        }
      }
    }
    const filterVal = {
      ...notificationsSettings,
      [value]: event.target.checked ? 1 : 0
    }
    setNotificationsSettings({
      ...notificationsSettings,
      [value]: event.target.checked ? 1 : 0
    })

    const areOtherValuesZero = Object.keys(filterVal)
      .filter(key => !['assign', 'unassign', 'muteAll', 'stageUpdate'].includes(key))
      .every(key => {
        // Also checking if allowNotificationStages is empty, and considering it as "0"
        return (
          filterVal[key] === 0 ||
          (Array.isArray(filterVal[key]) && filterVal[key].length === 0) ||
          (key === 'allowNotificationStages' && allowNotificationStages.length === 0)
        )
      })
    setDisableAll(areOtherValuesZero)
  }

  const handleToggleAll = event => {
    const newStatus = !disableAll
    setDisableAll(newStatus)
    let obj = {}
    if (newStatus) {
      setAllowNotificationStages([])
      filterPipeline?.forEach(item => {
        obj[item.name] = 0
      })
    } else {
      filterPipeline?.forEach(item => {
        obj[item.name] = 1
      })
      filterPipeline?.forEach(item => {
        allowNotificationStages.push(item?.stageId)
      })
    }
    const updatedSettings = Object?.keys(notificationsSettings)?.reduce((acc, key) => {
      if (key === 'muteAll') {
        acc[key] = newStatus ? 0 : 1
      } else if (
        key === 'bugFix' ||
        key === 'bugRaise' ||
        key === 'clientDocumentAdd' ||
        key === 'clientDocumentDelete' ||
        // key === 'stageUpdate' ||
        key === 'clientChat' ||
        key === 'teamChat' ||
        key === 'noteAdd' ||
        key === 'documentAdd' ||
        key === 'videoAdd' ||
        key === 'noteDelete' ||
        key === 'videoDelete' ||
        key === 'documentDelete'
      ) {
        acc[key] = newStatus ? 0 : 1
      } else {
        acc[key] = notificationsSettings[key]
      }
      return acc
    }, {})
    setNotificationsSettings({ ...updatedSettings, ...obj })
  }

  const handleSubmit = async () => {
    setSubmitLoading(true)

    try {
      const params = {
        assign: notificationsSettings?.assign,
        bugFix: notificationsSettings?.bugFix,
        bugRaise: notificationsSettings?.bugRaise,
        clientDocumentAdd: notificationsSettings?.clientDocumentAdd,
        clientDocumentDelete: notificationsSettings?.clientDocumentDelete,
        documentAdd: notificationsSettings?.documentAdd,
        documentDelete: notificationsSettings?.documentDelete,
        noteAdd: notificationsSettings?.noteAdd,
        noteDelete: notificationsSettings?.noteDelete,
        clientChat: notificationsSettings?.clientChat,
        teamChat: notificationsSettings?.teamChat,
        stageUpdate: 1,
        unassign: notificationsSettings?.unassign,
        videoAdd: notificationsSettings?.videoAdd,
        videoDelete: notificationsSettings?.videoDelete,
        muteAll: disableAll ? 1 : 0,
        allowNotificationStages: allowNotificationStages
      }
      let response = await editNotificationsGlobalUpdate(params)
      if (response?.data?.status_code == 201 || response?.data?.status_code == 200) {
        toast.success(response?.data?.message, {
          position: 'top-right',
          theme: 'colored'
        })
      } else {
        toast.error(response?.data?.message, {
          position: 'top-right',
          theme: 'colored'
        })
      }
    } catch (err) {
      console.error('Error updating notifications:', err)
    } finally {
      setSubmitLoading(false)
    }
  }

  const handleCloseModal = () => {
    setOpenNotificationModal(false)
    // setDisableAll(false)
    // setAllowNotificationStages([])
  }

  return (
    <>
      <Card
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '8px 15px',
          gap: '10px'
        }}
      >
        <FalconCardHeader title='Global Notifications' />

        <div className='py-2 px-3 bg-body-tertiary'>
          <div className=''>
            <div className='d-flex mt-3'>
              <label className='form-check-label fw-bolder' style={{ width: '170px' }}>
                Mute All Notifications
              </label>
              <div className='form-check form-switch'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={disableAll}
                  onChange={event => handleToggleAll(event)}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </div>

            {intalSettings.map(item => (
              <>
                <label key={item.id} className='form-check-label fw-bolder fs-10'>
                  {item.name}
                </label>
                <div className='d-flex flex-wrap '>
                  {item.child.map(item2 => (
                    <div className='d-flex ' key={item2.id}>
                      <label className='form-check-label fw-normal' style={{ width: '180px' }}>
                        {item2.name}
                      </label>
                      <div className='form-check form-switch'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={notificationsSettings ? notificationsSettings[item2?.value] === 1 : false}
                          onChange={e => handleCheckboxChange(e, item2?.value)}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ))}
          </div>
          <div className='d-flex justify-content-end w-100 gap-3'>
            <Button onClick={handleSubmit}>{submitLoading ? <Spinner size='sm' /> : 'Save'}</Button>
            <Button onClick={() => setOpenNotificationModal(true)}>Apply Globally</Button>
          </div>
        </div>
      </Card>
      <NotificationModal
        openNotificationModal={openNotificationModal}
        handleCloseModal={handleCloseModal}
        notificationsSettings={notificationsSettings}
        allowNotificationStages={allowNotificationStages}
        disableAll={disableAll}
      />
    </>
  )
}

export default GlobalNotification
