import React, { useState, useEffect } from 'react'
import { Card, OverlayTrigger, Tooltip, Badge } from 'react-bootstrap'
import { AiOutlineMessage } from 'react-icons/ai'
import SubtleBadge from 'components/common/SubtleBadge'
import { useDispatch, useSelector } from 'react-redux'
import { Encrypt } from 'utils/constants'
import { useNavigate } from 'react-router-dom'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { ListionUnreadMessage } from 'utils/socket'
import _ from 'lodash'
import Avatar from '../../common/Avatar'
import { IoBugOutline } from 'react-icons/io5'
import { ClientModal } from '../clientProjects/clientModal'
import NotificationPopup from 'components/app/projects/projectDetailViews/NotificationPopup'
import { IoMdNotificationsOutline } from 'react-icons/io'
import { MdOutlineNotificationsOff } from 'react-icons/md'

const AvailableJobs = ({ job }) => {
  const { userDetails } = useSelector(state => state.login)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { allProjectSettings } = useSelector(state => state.projects)
  const { allUnreadCounts } = useSelector(state => state.groupChat)

  let selectedProjectSetting = allProjectSettings?.find(item => item?.projectId === job?.id)
  const [projectDetail, setProjectDetail] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [totalUnreadCount, setTotalUnreadCount] = useState(0)
  const [openNotificationPopup, setOpenNotificationPopup] = useState(false)

  useEffect(() => {
    let ChatCountListing = allUnreadCounts?.filter(item => item?.room_id === job?.id) ?? []
    let clientChatUnreadCount = ChatCountListing?.find(item => item?.type === 'client')?.count
    setTotalUnreadCount(isNaN(clientChatUnreadCount) ? 0 : clientChatUnreadCount ?? 0)
  }, [allUnreadCounts])

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    if (selectedProjectSetting) {
      const updatedAssignments = selectedProjectSetting.assignments.map(assignment => {
        const skillDetail = _.find(selectedProjectSetting.skillsDetails, { _id: assignment.skill_id })
        if (skillDetail) {
          return {
            ...assignment,
            name: skillDetail.name
          }
        }
        return assignment
      })
      const updatedDataObject = {
        ...selectedProjectSetting,
        assignments: updatedAssignments
      }
      setProjectDetail(updatedDataObject)
      ListionUnreadMessage(dispatch)
    }
  }, [allProjectSettings])

  const handleRedirect = title => {
    dispatch({ type: 'REMOVE_STATES' })
    navigate(`/app/projects/${Encrypt(job?.id)}/detail/${Encrypt('In-progress')}`)
    if (title) {
      dispatch({ type: 'TOGGLE_DETAIL_MODAL', payload: { show: true, title: title } })
    }
  }

  const handleOpenNotificationPopup = () => {
    setOpenNotificationPopup(true)
  }

  const handleCloseNotificationPopup = () => {
    setOpenNotificationPopup(false)
  }

  let isAssingedSkills = projectDetail?.assignments?.some(item => item.assignee_id)
  let unAssingedSkills = projectDetail?.assignments?.some(item => !item.assignee_id)

  return (
    <div key={job?.id}>
      <Card className='kanban-item-card hover-actions-trigger'>
        <Card.Body>
          <>
            <div className='d-flex justify-content-between w-100'>
              <div>
                {job?.name && (
                  <p
                    className='mb-0 fw-medium font-sans-serif'
                    style={{ whiteSpace: 'wrap', overflow: 'hidden', wordBreak: 'break-all' }}
                  >
                    {job?.name.length > 80 ? job?.name.substring(0, 80) + '...' : job?.name}
                  </p>
                )}

                {projectDetail?.descriptions && (
                  <p
                    className='mb-0 fw-light font-sans-serif  fs-11'
                    style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '180px' }}
                  >
                    {projectDetail?.descriptions}
                  </p>
                )}
              </div>
              {/* <GroupMember
                avatarSize='l'
                users={selectedProjectSetting?.assignedDev}
                showMember={2}
                className='d-none d-sm-block'
              /> */}
            </div>
            <div className='mt-2' onClick={() => handleRedirect()}>
              {isAssingedSkills && (
                <div>
                  <p
                    style={{
                      color: '#000',
                      fontWeight: '500',
                      fontSize: '12px',
                      marginBottom: '5px',
                      marginTop: '5px'
                    }}
                  >
                    Assigned To:
                  </p>
                  <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
                    {projectDetail?.assignedDev?.map((item, index) => {
                      return (
                        <div
                          style={{ background: '#F5F1EE', borderRadius: '50px', marginBottom: '10px' }}
                          className={'d-flex  gap-1 align-items-center'}
                          key={index}
                        >
                          <Avatar name={item.name} size='xl' />
                          <p
                            style={{
                              color: '#000',
                              fontSize: '10px',
                              fontWeight: '500',
                              textAlign: 'center',
                              paddingRight: '10px'
                            }}
                          >
                            {' '}
                            {item.name}
                          </p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
              {unAssingedSkills && (
                <div>
                  <p
                    style={{
                      color: '#000',
                      fontWeight: '500',
                      fontSize: '12px',
                      marginBottom: '5px',
                      marginTop: '5px'
                    }}
                  >
                    Pending:
                  </p>
                  <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
                    {projectDetail?.assignments?.map((item, index) => {
                      if (!item?.assignee_id) {
                        return (
                          <>
                            <SubtleBadge
                              key={item?.skill_id}
                              className='py-2 me-1 mb-1 text-dark d-flex gap-1 align-items-center'
                            >
                              <div
                                style={{
                                  width: '8px',
                                  height: '8px',
                                  backgroundColor: '#FDD835',
                                  borderRadius: '50px'
                                }}
                              ></div>
                              {item?.name}
                            </SubtleBadge>
                          </>
                        )
                      }
                    })}
                  </div>
                </div>
              )}
            </div>

            <div className='d-flex justify-content-end align-item-center border-top mt-1 pt-1'>
              <div className='d-flex align-items-center gap-3'>
                {selectedProjectSetting?.bugCount > 0 && (
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip style={{ position: 'fixed' }} id='call'>
                        Bugs
                      </Tooltip>
                    }
                  >
                    <div className='position-relative' id='call'>
                      <IoBugOutline
                        onClick={handleOpenModal}
                        size={18}
                        style={{ cursor: 'pointer', color: selectedProjectSetting?.bugCount > 0 ? 'red' : '' }}
                      />
                      {selectedProjectSetting?.bugCount > 0 && (
                        <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>
                          {selectedProjectSetting?.bugCount}
                          <span className='visually-hidden'>unread messages</span>
                        </span>
                      )}
                    </div>
                  </OverlayTrigger>
                )}

                <OverlayTrigger
                  placement='top'
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip style={{ position: 'fixed' }} id='call'>
                      Conversations
                    </Tooltip>
                  }
                >
                  <div className='position-relative' id='call'>
                    <AiOutlineMessage onClick={() => handleRedirect()} size={18} />
                    {totalUnreadCount > 0 && (
                      <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>
                        {totalUnreadCount < 100 ? totalUnreadCount : '99+'}
                      </span>
                    )}
                  </div>
                </OverlayTrigger>
                <>
                  <div className='position-relative'>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip style={{ position: 'fixed' }} id='call'>
                          Documents
                        </Tooltip>
                      }
                    >
                      <div id='call'>
                        <IoDocumentTextOutline onClick={() => handleRedirect('Client Documents')} size={18} />
                      </div>
                    </OverlayTrigger>
                  </div>
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip style={{ position: 'fixed' }} id='call'>
                        Notifications
                      </Tooltip>
                    }
                  >
                    <div id='call'>
                      {selectedProjectSetting?.notificationSettings ? (
                        <>
                          {!Object.keys(selectedProjectSetting?.notificationSettings)?.length ||
                          selectedProjectSetting?.notificationSettings?.muteAll ? (
                            <MdOutlineNotificationsOff onClick={handleOpenNotificationPopup} size={18} />
                          ) : (
                            <IoMdNotificationsOutline onClick={handleOpenNotificationPopup} size={18} />
                          )}
                        </>
                      ) : (
                        <IoMdNotificationsOutline onClick={handleOpenNotificationPopup} size={18} />
                      )}
                    </div>
                  </OverlayTrigger>
                </>
              </div>
            </div>
          </>
        </Card.Body>
      </Card>
      <ClientModal
        show={openModal}
        handleClose={handleCloseModal}
        projectDetail={projectDetail}
        stage='In-progress'
        setOpenClientModal={setOpenModal}
      />
      <NotificationPopup
        openNotificationPopup={openNotificationPopup}
        handleCloseNotificationPopup={handleCloseNotificationPopup}
        task={job}
      />
    </div>
  )
}

export default AvailableJobs
