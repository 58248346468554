import React, { useEffect, useState, useRef } from 'react'

import Message from './Message'
import SimpleBarReact from 'simplebar-react'
import { getOldConvMessages, getDevOldConvMessages } from '../../../../redux/smsMail/action'
import { useSelector, useDispatch } from 'react-redux'
import { Image, Spinner, Tooltip, OverlayTrigger, Accordion } from 'react-bootstrap'
import Avatar from 'components/common/Avatar'
import Flex from 'components/common/Flex'
import classNames from 'classnames'
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery'
import dayjs from 'dayjs'
import { IoMdDownload } from 'react-icons/io'
import axios from 'axios'
import Card from 'react-bootstrap/Card'
import Collapse from 'react-bootstrap/Collapse'
import { MdOutlineEmail } from 'react-icons/md'
import { IoIosArrowDown } from 'react-icons/io'
import { getEmailDetailUnshift } from '../../../../redux/smsMail/action'
import { LuReply } from 'react-icons/lu'
import { MdExpandMore } from 'react-icons/md'
import { isAdmin, setCurrentUser } from 'utils/constants'
import MessageIcon from '../../../../assets/img/NoMessages.svg'

const ChatContentBody = ({ thread, loader }) => {
  const { ConvMessages, Conversations, sendThreadMessage, isConvMessages } = useSelector(state => state.smsEmail)
  const [smsConversationArr, setConversationArr] = useState([])
  const dispatch = useDispatch()
  const { jobDetail, usersList } = useSelector(state => state.users)
  const { userDetails } = useSelector(state => state.login)
  const [intialLoader, setIntialLoader] = useState(true)
  const { isEmailDetail } = useSelector(state => state.smsEmail)
  const [open, setOpen] = useState('')
  const [activeIndex, setActiveIndex] = useState(-1)
  // const [currentPage, setCurrentPage] = useState(1);

  const messagesEndRef = useRef()

  const [windowHeight, setWindowHeight] = useState(
    sendThreadMessage?.isOpen && sendThreadMessage?.selectedTab === 'sms'
      ? window.innerHeight - 365
      : sendThreadMessage?.isOpen && sendThreadMessage?.selectedTab === 'email'
      ? window.innerHeight - 440
      : window.innerHeight - 215
  )
  const [width, setWidth] = useState(window.innerWidth)
  const { PorjectSettings } = useSelector(state => state.projects)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setIntialLoader(false)
    }, 4000)
  })
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowHeight(
        sendThreadMessage?.isOpen && sendThreadMessage?.selectedTab === 'sms'
          ? window.innerHeight - 365
          : sendThreadMessage?.isOpen && sendThreadMessage?.selectedTab === 'email'
          ? window.innerHeight - 440
          : window.innerHeight - 215
      )
    }

    handleWindowResize() // Call it once to set the initial height

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [sendThreadMessage?.isOpen, sendThreadMessage?.selectedTab])

  useEffect(() => {
    const handleBeforeUnload = () => {
      setConversationArr([])
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    if (!loader) {
      setTimeout(() => {
        messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' })
      }, 500)
    }
  }, [loader])

  const handleFeatchAllEmails = async (emailItems, currentPage, isLoad) => {
    if (isLoad) {
      // if (!emailItems?.emailThreads) {
      const messageIds = emailItems?.meta?.email?.messageIds
      if (messageIds) {
        const totalEmails = messageIds.length
        const emailIndex = totalEmails - currentPage

        if (emailIndex >= 0) {
          const emailId = messageIds[emailIndex]
          await dispatch(getEmailDetailUnshift({ email_id: emailId }))
          // setCurrentPage(currentPage + 1)
        }
        // }
      }
    }
  }

  const downloadFile = async (url, fileName = 'mtc-files') => {
    try {
      const response = await axios.get(url, {
        responseType: 'blob' // Important
      })

      // Create a blob from the response
      const blob = new Blob([response.data], { type: response.headers['content-type'] })

      // Create a link element to trigger the download
      const downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(blob)
      downloadLink.download = fileName

      // Trigger the download
      downloadLink.click()
    } catch (error) {
      console.error('Error downloading file:', error)
    }
  }

  const sendThreadMessageFn = (threadId, threadSub) => {
    dispatch({
      type: 'SEND_THREAD_MESSAGE',
      payload: {
        threadId: threadId,
        isOpen: true,
        threadSubject: threadSub,
        selectedTab: 'email'
      }
    })
  }
  useEffect(() => {
    setConversationArr([])
    if (ConvMessages?.messages?.length > 0) {
      setConversationArr(ConvMessages?.messages ?? [])
    }
  }, [ConvMessages, ConvMessages?.messages])

  const handleLoadMore = () => {
    if (Conversations?.length > 0 && isAdmin()) {
      dispatch(
        getOldConvMessages({
          conversationId: Conversations[0]?.id,
          type: 'TYPE_SMS,TYPE_EMAIL',
          lastMessageId: ConvMessages?.lastMessageId
        })
      )
    } else if (Conversations?.length > 0 && !isAdmin()) {
      let conversationType = ''
      if (PorjectSettings?.sms_chat_enabled === 1 && PorjectSettings?.email_chat_enabled === 1) {
        conversationType = 'TYPE_SMS,TYPE_EMAIL'
      } else if (PorjectSettings?.sms_chat_enabled === 1 && PorjectSettings?.email_chat_enabled === 0) {
        conversationType = 'TYPE_SMS'
      } else if (PorjectSettings?.sms_chat_enabled === 0 && PorjectSettings?.email_chat_enabled === 1) {
        conversationType = 'TYPE_EMAIL'
      }
      dispatch(
        getDevOldConvMessages({
          conversationId: Conversations[0]?.id,
          type: conversationType,
          lastMessageId: ConvMessages?.lastMessageId
        })
      )
    }
  }

  const handleOpenCollapse = id => {
    setActiveIndex(-1)
    // setCurrentPage(1)
    if (open == id) {
      setOpen('')
    } else {
      setOpen(id)
    }
  }

  return (
    <div
      className='chat-content-body'
      style={{
        display: 'inherit',
        height: `${windowHeight}px`
      }}
    >
      {!intialLoader && !isConvMessages && ConvMessages?.messages?.length <= 0 ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img src={MessageIcon} alt='dmd' />
          <h5 style={{ fontSize: '15px', fontWeight: '600', color: '#000' }}>Let's Start a Conversation</h5>
          <p style={{ textAlign: 'center', color: 'rgb(153, 153, 153)' }}>
            Transform your plans into progress. Collaborate with our <br /> developers and elevate your trading
            experience. <br />
            Start your conversation now!
          </p>
        </div>
      ) : (
        <SimpleBarReact style={{ height: '100%' }}>
          {ConvMessages?.nextPage && (
            <div onClick={handleLoadMore} className='text-center fs-9 text-800 fw-semibold mt-2 cursor-pointer'>
              <u>Load More</u>
            </div>
          )}
          <div className='chat-content-scroll-area'>
            {smsConversationArr?.map((item, index) => {
              // let isLeft = !item?.userId
              let isLeft = item?.type == 21 || !item?.userId
              let sender = null
              if (item?.userId) {
                let userIndex = usersList?.findIndex(user => user?.id === item?.userId)
                if (userIndex !== -1) {
                  sender = usersList[userIndex]
                } else {
                  sender = { name: 'Admin' }
                }
              }

              return (
                <>
                  <div key={index}>
                    {item?.type === 2 && <Message message={item} />}
                    {(item?.type === 3 || item?.type == 21) && (
                      <>
                        {' '}
                        <Flex className={classNames('p-3', { 'd-block': !isLeft })}>
                          {isLeft && (
                            <OverlayTrigger
                              placement='top'
                              delay={{ show: 250, hide: 400 }}
                              overlay={<Tooltip id='button-tooltip'>{jobDetail?.contact?.name}</Tooltip>}
                            >
                              <div>
                                <Avatar size='l' className='me-2' name={jobDetail?.contact?.name} />
                              </div>
                            </OverlayTrigger>
                          )}
                          <div
                            className={classNames('flex-1', {
                              'd-flex justify-content-end': !isLeft
                            })}
                          >
                            <div
                              className={classNames('w-xxl-75', {
                                'w-100': !isLeft
                              })}
                            >
                              <Flex
                                alignItems='center'
                                className={classNames('hover-actions-trigger', {
                                  'flex-end-center': !isLeft,
                                  'align-items-center': isLeft
                                })}
                              >
                                <div className='w-75'>
                                  <div
                                    style={{
                                      transition: open == '' ? 'border-radius 2s' : 'none',
                                      background: isLeft ? '#F6F3FB' : '#C7A9F1',
                                      borderRadius: open == item?.id ? '10px 10px 0px 0px' : '10px'
                                    }}
                                    onClick={() => {
                                      handleFeatchAllEmails(item, 1, item?.emailThreads?.length > 0 ? false : true)
                                      handleOpenCollapse(item?.id)
                                    }}
                                    className={classNames(
                                      'p-2  d-flex align-items-center justify-content-between flex-wrap',
                                      {
                                        'text-dark': isLeft,
                                        'text-white': !isLeft
                                      }
                                    )}
                                  >
                                    <div className='d-flex  gap-2'>
                                      {isEmailDetail && open === item?.id ? (
                                        <div>
                                          <Spinner size='sm' />
                                        </div>
                                      ) : (
                                        <MdOutlineEmail size={20} />
                                      )}
                                      <p
                                        className='mb-0 mt-0 fw-bolder'
                                        style={{
                                          overflow: 'hidden',
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          maxWidth: width < 769 ? '100px' : '200px' // Adjust this width as needed
                                        }}
                                      >
                                        {item?.subject}
                                      </p>{' '}
                                      {width > 769 && `-`}
                                      {/* <p className='fw-bold'>{`(${item?.emailThreads
                                        ? item?.emailThreads?.length
                                        : item?.meta?.email?.messageIds?.length
                                        })`}</p> */}
                                      <p className='fw-bold'>{`(${
                                        item?.emailThreads?.length > item?.meta?.email?.messageIds?.length
                                          ? item?.emailThreads?.length
                                          : item?.meta?.email?.messageIds?.length
                                      })`}</p>
                                      <p
                                        className='mb-0 mt-0'
                                        style={{
                                          flex: 1,
                                          overflow: 'hidden',
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          width: '100px' // Change 'width' to 'maxWidth'
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: width > 769 ? item?.lastMessage?.replace(/(<([^>]+)>)/gi, '') : ''
                                        }}
                                      ></p>
                                    </div>
                                    <div className='d-flex align-items-center gap-2'>
                                      <div className='  fs-11 '>
                                        {dayjs(item?.dateAdded).format('YYYY-MM-DD hh:mm a')}
                                      </div>
                                      <IoIosArrowDown
                                        onClick={() => {
                                          handleOpenCollapse(item?.id)
                                        }}
                                        style={{ cursor: 'pointer' }}
                                      />
                                    </div>
                                  </div>

                                  <Collapse in={open === item?.id} dimension='height'>
                                    <div
                                      style={{
                                        background: isLeft ? '#F6F3FB' : '#C7A9F1',
                                        borderRadius: open == item?.id ? '0px 0px 10px 10px' : 'none'
                                      }}
                                      className={classNames('p-2 ', {
                                        'text-dark': isLeft,
                                        'text-white': !isLeft
                                      })}
                                      id='example-collapse-text'
                                    >
                                      {!isEmailDetail &&
                                        item?.emailThreads?.length < item?.meta?.email?.messageIds?.length && (
                                          <div className={'text-center mb-1'}>
                                            <p
                                              className='fw-bolder text-decoration-underline	 cursor-pointer'
                                              onClick={() =>
                                                handleFeatchAllEmails(item, item?.emailThreads?.length + 1, true)
                                              }
                                            >
                                              Load Previous mails
                                            </p>
                                          </div>
                                        )}

                                      <Card
                                        style={{ background: isLeft ? '#F6F3FB' : '#C7A9F1' }}
                                        className={classNames('p-2 shadow-none rounded-2 ', {
                                          'text-dark': isLeft,
                                          'text-white': !isLeft
                                        })}
                                      >
                                        {item?.emailThreads?.map((emailObj, index) => {
                                          return (
                                            <>
                                              <Accordion activeKey={activeIndex}>
                                                <Accordion.Item
                                                  eventKey={index}
                                                  style={{
                                                    marginBottom: '10px',
                                                    backgroundColor: isLeft ? '#FFF' : '#ECE3F8',
                                                    borderRadius: '10px',
                                                    border: 'none',
                                                    cursor: 'pointer'
                                                  }}
                                                  onClick={() => {
                                                    if (index === activeIndex) {
                                                      setActiveIndex(-1)
                                                    } else {
                                                      setActiveIndex(index)
                                                    }
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      gap: '10px',
                                                      alignItems: 'center',
                                                      padding: '7px',
                                                      justifyContent: 'space-between',
                                                      flexWrap: 'wrap'
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        gap: '10px',
                                                        alignItems: 'center'
                                                      }}
                                                    >
                                                      <div style={{ width: '30px' }}>
                                                        <Avatar
                                                          name={
                                                            emailObj?.userId ? sender?.name : jobDetail?.contact?.name
                                                          }
                                                        />
                                                      </div>
                                                      <div>
                                                        <p
                                                          className='mb-0 fw-bolder mt-0'
                                                          style={{ fontWeight: '500', color: '#000', fontSize: '14px' }}
                                                        >
                                                          {' '}
                                                          {emailObj?.userId ? sender?.name : jobDetail?.contact?.name}
                                                        </p>
                                                        <span
                                                          style={{
                                                            marginRight: '5px',
                                                            fontWeight: '500',
                                                            color: '#000',
                                                            fontSize: '12px'
                                                          }}
                                                        >
                                                          To:
                                                        </span>
                                                        <span
                                                          style={{
                                                            fontWeight: '400',
                                                            color: '#000',
                                                            fontSize: '12px'
                                                          }}
                                                        >
                                                          {emailObj?.userId ? jobDetail?.contact?.name : sender?.name}
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className='d-flex align-items-center gap-2'>
                                                      <div>
                                                        <LuReply
                                                          onClick={() =>
                                                            sendThreadMessageFn(emailObj?.threadId, item?.subject)
                                                          }
                                                          style={{ cursor: 'pointer' }}
                                                          size={20}
                                                        />
                                                      </div>
                                                      <div className='  fs-11 '>
                                                        {dayjs(emailObj.dateAdded).format('YYYY-MM-DD hh:mm a')}
                                                      </div>
                                                      <MdExpandMore
                                                        onClick={() => {
                                                          if (index === activeIndex) {
                                                            setActiveIndex(-1)
                                                          } else {
                                                            setActiveIndex(index)
                                                          }
                                                        }}
                                                        style={{ fontSize: '20px', cursor: 'pointer', color: '#000' }}
                                                      />
                                                    </div>
                                                  </div>
                                                  <Accordion.Body>
                                                    <div className='border-bottom mt-2' key={index}>
                                                      <>
                                                        <div className='d-flex justify-content-between w-100 '>
                                                          <div style={{ width: '90%' }} className='d-flex gap-2'>
                                                            {/* <div style={{ width: '30px' }}>
                                                              <Avatar name={emailObj?.from?.substring(0, 3)} />
                                                            </div> */}
                                                            <div>
                                                              {/* <p className='mb-0 fw-bolder mt-0'> {emailObj?.from}</p>
                                                              <p className='mb-0'>
                                                                <b>To</b> {emailObj?.to}
                                                              </p> */}

                                                              <div className='mt-0' style={{ overflow: 'hidden' }}>
                                                                <div
                                                                  className='mb-0 email-content'
                                                                  style={{
                                                                    wordBreak: 'break-all !important',
                                                                    overflow: 'hidden !important'
                                                                  }}
                                                                  dangerouslySetInnerHTML={{ __html: emailObj?.body }}
                                                                ></div>

                                                                <FalconLightBoxGallery images={emailObj.attachments}>
                                                                  {setImgIndex => (
                                                                    <div className='d-flex gap-2 flex-wrap'>
                                                                      {emailObj?.attachments?.length > 0 &&
                                                                        emailObj.attachments?.map((img, index) => {
                                                                          const isImage = [
                                                                            '.png',
                                                                            '.jpg',
                                                                            '.png',
                                                                            'mpeg',
                                                                            'jpeg'
                                                                          ].some(ext => img.endsWith(ext))

                                                                          return (
                                                                            <>
                                                                              {isImage ? (
                                                                                <div>
                                                                                  <Image
                                                                                    bsPrefix
                                                                                    rounded
                                                                                    width={width < 500 ? 180 : 300}
                                                                                    height={width < 500 ? 100 : 200}
                                                                                    className='mb-2 cursor-pointer'
                                                                                    src={img}
                                                                                    alt=''
                                                                                    onClick={() => setImgIndex(index)}
                                                                                  />
                                                                                </div>
                                                                              ) : (
                                                                                <div
                                                                                  className={classNames(
                                                                                    'p-2 mb-1 rounded-2 d-flex align-items-center gap-1 justify-content-between',
                                                                                    {
                                                                                      'bg-primary  text-white ': isLeft,
                                                                                      'bg-200 text-dark': !isLeft
                                                                                    }
                                                                                  )}
                                                                                  onClick={() => downloadFile(img)}
                                                                                >
                                                                                  Download File{' '}
                                                                                  <IoMdDownload
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    size={16}
                                                                                  />
                                                                                </div>
                                                                              )}
                                                                            </>
                                                                          )
                                                                        })}
                                                                    </div>
                                                                  )}
                                                                </FalconLightBoxGallery>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div style={{ width: '6%' }}>
                                                            {/* <LuReply
                                                            onClick={() => sendThreadMessage(emailObj?.threadId)}
                                                            style={{ cursor: 'pointer' }}
                                                            size={20}
                                                          /> */}
                                                          </div>
                                                        </div>
                                                      </>
                                                    </div>
                                                  </Accordion.Body>
                                                </Accordion.Item>
                                              </Accordion>
                                            </>
                                          )
                                        })}
                                      </Card>
                                    </div>
                                  </Collapse>
                                </div>
                              </Flex>
                              <div
                                className={classNames('text-400 fs-11', {
                                  'text-end': !isLeft
                                })}
                              >
                                {dayjs(item.dateAdded).format('YYYY-MM-DD hh:mm a')}
                              </div>
                            </div>
                          </div>
                        </Flex>
                      </>
                    )}
                  </div>
                </>
              )
            })}
          </div>
          <div ref={messagesEndRef} />
        </SimpleBarReact>
      )}
    </div>
  )
}

export default ChatContentBody
