import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { IoMdClose } from 'react-icons/io'

const ConfirmSwap = props => {
  const handleSubmit = async () => {
    props.setModalShow(true)
    props.onHide()
  }

  const handleClose = () => {
    props.onHide()
  }
  return (
    <Modal
      {...props}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      style={{
        maxWidth: '380px',
        margin: 'auto',
        left: 0,
        right: 0
      }}
    >
      <div className='p-3'>
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <img
            className='me-2'
            src={'https://mytradingcoder.com/wp-content/uploads/2023/12/mtc-logo-mobile.svg'}
            alt='Logo'
            width={60}
          />{' '}
          <div onClick={handleClose}>
            <IoMdClose size={24} className='cursor-pointer' />
          </div>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: props?.otpErrorMessage
          }}
          style={{
            textAlign: 'center'
          }}
        />
        <div className='d-flex  justify-content-center mt-4 mb-3 gap-2'>
          <Button onClick={handleClose} color='primary' className='w-50'>
            No
          </Button>
          <Button onClick={handleSubmit} color='primary' className='w-50'>
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmSwap
