import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, Button, Form, Spinner } from 'react-bootstrap'
import warningIcon from '../../../../assets/warning.svg'
import { toast } from 'react-toastify'
import { editNotificationsOverrideGlobal } from '../../../../redux/projects/action'

const NotificationApplyModal = ({
  openNotificationApplyModal,
  handleCloseApplyModal,
  notificationsSettings,
  allowNotificationStages,
  setNotificationsSettings,
  filterPipeline,
  setDisableAll,
  setAllowNotificationStages
}) => {
  const [applyLoader, setApplyLoader] = useState(false)

  const handleApplyGlobal = async () => {
    setApplyLoader(true)
    setAllowNotificationStages([])
    try {
      const params = {
        project_id: notificationsSettings?.project_id
      }
      let response = await editNotificationsOverrideGlobal(params)
      let obj = {}
      let arr = []
      filterPipeline?.forEach(item => {
        response?.data?.data?.allowNotificationStages?.forEach(stage => {
          if (item?.stageId == stage) {
            obj[item.name] = 1
            arr.push(stage)
          }
        })
      })
      setAllowNotificationStages(arr)
      setDisableAll(response?.data?.data?.muteAll == 1 ? true : false)
      setNotificationsSettings({ ...response?.data?.data, ...obj, project_id: notificationsSettings?.project_id })
      if (response?.data?.status_code == 201 || response?.data?.status_code == 200) {
        toast.success(response?.data?.message, {
          position: 'top-right',
          theme: 'colored'
        })
      } else {
        toast.error(response?.data?.message, {
          position: 'top-right',
          theme: 'colored'
        })
      }
    } catch (err) {
      console.error('Error updating notifications:', err)
    } finally {
      setApplyLoader(false)
      handleCloseApplyModal()
    }
  }
  return (
    <Modal
      show={openNotificationApplyModal}
      onHide={handleCloseApplyModal}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      style={{
        border: 'none'
      }}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body
        style={{
          height: 'auto',
          overflow: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '10px'
        }}
      >
        <img src={warningIcon} alt='dmd' style={{ width: '64px', height: '64px' }} />
        <p style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600' }}> Apply Global Settings</p>
        <p style={{ textAlign: 'center', fontSize: '13px', fontWeight: '400', color: 'rgba(131, 130, 137, 1)' }}>
          Are you sure you want to copy the global settings to this project? This will override any existing settings
          for the project.
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px'
          }}
        >
          <Button variant='secondary' onClick={handleCloseApplyModal}>
            Cancel
          </Button>
          <Button variant='primary' onClick={handleApplyGlobal} disabled={applyLoader}>
            {applyLoader ? <Spinner size='sm' /> : 'Apply'}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default NotificationApplyModal
