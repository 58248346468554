import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Dropdown, Spinner } from 'react-bootstrap'
import SubtleBadge from 'components/common/SubtleBadge'
import { Draggable } from 'react-beautiful-dnd'
import { AiOutlineMessage } from 'react-icons/ai'
import { PiNotebookThin } from 'react-icons/pi'
import KanbanModal from './KanbanModal'
import { Decrypt, Encrypt, formatBudget } from 'utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import authConfig from 'utils/authConfig'
import GroupMember from './GroupAvatar'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { IoVideocamOutline } from 'react-icons/io5'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { ListionUnreadMessage } from 'utils/socket'
import { RiDeleteBinLine } from 'react-icons/ri'
import _ from 'lodash'
import { TaskDeleteModal } from './TaskDeleteModal'
import { IoBugOutline } from 'react-icons/io5'
import { AdminClientModal } from './salesBugScreen/AdminClientModal'
import NotificationPopup from 'components/app/projects/projectDetailViews/NotificationPopup'
import { IoMdNotificationsOutline } from 'react-icons/io'
import { MdOutlineNotificationsOff } from 'react-icons/md'

const TaskCard = ({ task, index }) => {
  const { allProjectSettings, isCardMoving, cardMovingId, pipelines } = useSelector(state => state.projects)
  const { allUnreadCounts } = useSelector(state => state.groupChat)

  const { savedPipleline } = useSelector(state => state.calenders)

  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [selectedTask, setSelected] = useState(null)
  const navigate = useNavigate()
  const [projectDetail, setProjectDetail] = useState(null)
  const [openTaskDelete, setOpenTaskDelete] = useState(false)
  const [openNotificationPopup, setOpenNotificationPopup] = useState(false)

  const [open, setOpen] = useState(false)
  const cardRef = useRef(null)
  const [totalUnreadCount, setTotalUnreadCount] = useState(0)
  let IncrypteduserType = localStorage.getItem(authConfig.rolesKey)
  let userType = Decrypt(IncrypteduserType)
  let selectedProjectSetting = allProjectSettings?.find(item => item?.projectId === task?.id)
  let selectedPipeLine = pipelines?.find(item => item?.id == savedPipleline?.pipelineId)

  useEffect(() => {
    let ChatCountListing = allUnreadCounts?.filter(item => item?.room_id === task?.id) ?? []
    let clientChatUnreadCount = ChatCountListing?.find(item => item?.type === 'client')?.count ?? 0
    let teamChatUnreadCount = ChatCountListing?.find(item => item?.type === 'team')?.count ?? 0

    setTotalUnreadCount(
      isNaN(clientChatUnreadCount + teamChatUnreadCount) ? 0 : clientChatUnreadCount + teamChatUnreadCount ?? 0
    )
  }, [allUnreadCounts])

  useEffect(() => {
    if (selectedProjectSetting) {
      const updatedAssignments = selectedProjectSetting?.assignments.map(assignment => {
        const skillDetail = _.find(selectedProjectSetting?.skillsDetails, { _id: assignment?.skill_id })
        if (skillDetail) {
          return {
            ...assignment,
            name: skillDetail.name
          }
        }
        return assignment
      })
      const updatedDataObject = {
        ...selectedProjectSetting,
        assignments: updatedAssignments
      }
      setProjectDetail(updatedDataObject)
      ListionUnreadMessage(dispatch)
    }
  }, [allProjectSettings])

  const handleOpen = (isIcons, item, OpenModal) => {
    switch (isIcons) {
      case 'popup':
        setSelected(item)
        dispatch({ type: 'REMOVE_STATES' })
        navigate(`/app/projects/${Encrypt(item?.id)}/${Encrypt(item?.pipelineStageId)}/detail`)
        if (OpenModal) {
          dispatch({ type: 'TOGGLE_DETAIL_MODAL', payload: { show: true, title: OpenModal } })
        }
        break

      case 'new screen':
        // if (userType === 4 && (selectedPipeLine?.stages[0]?.id === item?.pipelineStageId || selectedPipeLine?.stages[1]?.id === item?.pipelineStageId)) {
        if (userType === 4) {
          setSelected(item)
          setShow(true)
        }
        break
    }
  }

  const handleToggle = () => {
    setSelected(null)
    setShow(false)
  }

  const getItemStyle = isDragging => ({
    cursor: isDragging ? 'grabbing' : 'pointer',
    transform: isDragging ? 'rotate(-2deg)' : '',
    backgroundColor: isDragging ? '#F6F1FF' : '#fff'
  })

  let isAssingedSkills = projectDetail?.assignments?.some(item => item?.assignee_id)
  let unAssingedSkills = projectDetail?.assignments?.some(item => !item?.assignee_id)
  let AssignedUsers = selectedProjectSetting?.assignedDev?.filter(item => item?.name !== 'Admin')

  let isNotFullfield = projectDetail?.assignments?.every(item => item?.assignee_id)

  const notOnBeginningStage =
    pipelines.length > 0
      ? selectedPipeLine?.stages[0]?.id !== task?.pipelineStageId &&
        selectedPipeLine?.stages[1]?.id !== task?.pipelineStageId
      : false

  const handleOpenTaskDelete = () => {
    setOpenTaskDelete(true)
  }

  const handleCloseTaskDelete = () => {
    setOpenTaskDelete(false)
  }
  const handleOpenNotificationPopup = () => {
    setOpenNotificationPopup(true)
  }

  const handleCloseNotificationPopup = () => {
    setOpenNotificationPopup(false)
  }
  const handleOpenModal = item => {
    setOpen(true)
  }
  const handleCloseModal = () => {
    setOpen(false)
  }

  return (
    <>
      <Draggable
        draggableId={`${task?.id}`}
        index={index}
        isDragDisabled={
          task?.pipelineStageId !== selectedPipeLine?.stages[0]?.id &&
          task?.pipelineStageId !== selectedPipeLine?.stages[1]?.id
            ? true
            : false
        }
      >
        {(provided, snapshot) => (
          <div
            ref={el => {
              provided.innerRef(el)
              cardRef.current = el
            }}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={provided.draggableProps.style}
            className='kanban-item'
          >
            <Card
              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
              className='kanban-item-card hover-actions-trigger'
            >
              <Card.Body
                style={{
                  padding: '0px',
                  backgroundColor: isCardMoving && cardMovingId === task?.id ? '#F6F1FF' : '',
                  position: 'relative'
                }}
              >
                <div
                  className='border-bottom '
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '6px 0px',
                    marginBottom: '7px'
                  }}
                >
                  <RiDeleteBinLine
                    size={21}
                    className='cursor-pointer'
                    style={{ color: '#e63757', marginRight: '10px' }}
                    onClick={handleOpenTaskDelete}
                  />
                </div>
                <div onClick={() => handleOpen('new screen', task)}>
                  {isNotFullfield === false && notOnBeginningStage && (
                    <div
                      style={{
                        background: '#fc00000f',
                        padding: '2px 10px',
                        marginBottom: '10px',
                        borderRadius: '20px',
                        display: 'flex',
                        gap: '3px',
                        alignItems: 'center',
                        margin: '1rem'
                      }}
                    >
                      <AiOutlineExclamationCircle size={14} style={{ color: '#EB5156' }} />
                      <p className='text-danger  mb-0 fw-semibold '>Skills requirements have not been fulfilled.</p>
                    </div>
                  )}

                  <div className='d-flex justify-content-between' style={{ padding: '0rem 1rem' }}>
                    <div>
                      <div className='mb-2 d-flex align-items-center justify-content-between'>
                        <SubtleBadge className='py-1 me-1 mb-1 text-dark'>{task?.status}</SubtleBadge>
                      </div>
                      {task?.name && (
                        <p
                          className='mb-0 fw-medium font-sans-serif'
                          style={{ whiteSpace: 'wrap', overflow: 'hidden', wordBreak: 'break-all' }}
                        >
                          {task?.name?.length > 80 ? task?.name?.substring(0, 80) + '...' : task?.name}
                        </p>
                      )}

                      {projectDetail?.descriptions && (
                        <p
                          className='mb-0 fw-light font-sans-serif  fs-11'
                          style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '200px' }}
                        >
                          {projectDetail?.descriptions}
                        </p>
                      )}
                    </div>

                    <GroupMember
                      avatarSize='l'
                      users={AssignedUsers?.length > 0 ? AssignedUsers : []}
                      showMember={2}
                      className='d-none d-sm-block'
                    />
                  </div>

                  <div className='mt-2' style={{ padding: '0rem 1rem' }}>
                    {isAssingedSkills && (
                      <div>
                        <p
                          style={{
                            color: '#000',
                            fontWeight: '500',
                            fontSize: '12px',
                            marginBottom: '5px',
                            marginTop: '5px'
                          }}
                        >
                          Assigned:
                        </p>
                        <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
                          {projectDetail?.assignments?.map((item, index) => {
                            if (item?.assignee_id) {
                              return (
                                <SubtleBadge
                                  key={item?.skill_id}
                                  className='py-2 me-1 mb-1 text-dark d-flex gap-1 align-items-center'
                                >
                                  <div
                                    style={{
                                      width: '8px',
                                      height: '8px',
                                      backgroundColor: '#2FCC68',
                                      borderRadius: '50px'
                                    }}
                                  ></div>
                                  {item?.name}
                                </SubtleBadge>
                              )
                            }
                          })}
                        </div>
                      </div>
                    )}
                    {unAssingedSkills && (
                      <div>
                        <p
                          style={{
                            color: '#000',
                            fontWeight: '500',
                            fontSize: '12px',
                            marginBottom: '5px',
                            marginTop: '5px'
                          }}
                        >
                          Unassigned:
                        </p>
                        <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
                          {projectDetail?.assignments?.map((item, index) => {
                            if (!item?.assignee_id) {
                              return (
                                <>
                                  <SubtleBadge
                                    key={item?.skill_id}
                                    className='py-2 me-1 mb-1 text-dark d-flex gap-1 align-items-center'
                                  >
                                    <div
                                      style={{
                                        width: '8px',
                                        height: '8px',
                                        backgroundColor: '#FDD835',
                                        borderRadius: '50px'
                                      }}
                                    ></div>
                                    {item?.name}
                                  </SubtleBadge>
                                </>
                              )
                            }
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='d-flex justify-content-between  border-top mt-1 pt-2' style={{ padding: '10px' }}>
                  <h6 style={{ fontWeight: '600' }} className='mb-0 font-sans-serif fs-9 '>
                    ${formatBudget(selectedProjectSetting?.price ?? 0, 2)}
                  </h6>

                  <div className='d-flex  gap-3'>
                    {selectedProjectSetting?.bugCount > 0 ||
                    task?.pipelineStageId === selectedPipeLine?.stages[selectedPipeLine?.stages?.length - 1]?.id ||
                    task?.pipelineStageId === selectedPipeLine?.stages[selectedPipeLine?.stages?.length - 2]?.id ? (
                      <OverlayTrigger
                        placement='top'
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip style={{ position: 'fixed' }} id='call'>
                            Bugs
                          </Tooltip>
                        }
                      >
                        <div className='position-relative' id='call'>
                          <IoBugOutline
                            onClick={() => handleOpenModal(task)}
                            size={18}
                            style={{ cursor: 'pointer', color: selectedProjectSetting?.bugCount > 0 ? 'red' : '' }}
                          />
                          {selectedProjectSetting?.bugCount > 0 && (
                            <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>
                              {selectedProjectSetting?.bugCount}
                              <span className='visually-hidden'>unread messages</span>
                            </span>
                          )}
                        </div>
                      </OverlayTrigger>
                    ) : (
                      ''
                    )}

                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip style={{ position: 'fixed' }} id='call'>
                          Conversations
                        </Tooltip>
                      }
                    >
                      <div className='position-relative' id='call'>
                        <AiOutlineMessage onClick={() => handleOpen('popup', task)} size={18} />

                        {totalUnreadCount > 0 && (
                          <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>
                            {totalUnreadCount < 100 ? totalUnreadCount : '99+'}
                          </span>
                        )}
                      </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip style={{ position: 'fixed' }} id='call'>
                          Notes
                        </Tooltip>
                      }
                    >
                      <div id='call'>
                        <PiNotebookThin onClick={() => handleOpen('popup', task, 'Notes')} size={18} />
                      </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip style={{ position: 'fixed' }} id='call'>
                          Documents
                        </Tooltip>
                      }
                    >
                      <div id='call'>
                        <IoDocumentTextOutline onClick={() => handleOpen('popup', task, 'Documents')} size={18} />
                      </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip style={{ position: 'fixed' }} id='call'>
                          Videos
                        </Tooltip>
                      }
                    >
                      <div id='call'>
                        <IoVideocamOutline onClick={() => handleOpen('popup', task, 'Videos')} size={18} />
                      </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip style={{ position: 'fixed' }} id='call'>
                          Notifications
                        </Tooltip>
                      }
                    >
                      <div id='call'>
                        {selectedProjectSetting?.notificationSettings ? (
                          <>
                            {!Object.keys(selectedProjectSetting?.notificationSettings)?.length ||
                            selectedProjectSetting?.notificationSettings?.muteAll ? (
                              <MdOutlineNotificationsOff onClick={handleOpenNotificationPopup} size={18} />
                            ) : (
                              <IoMdNotificationsOutline onClick={handleOpenNotificationPopup} size={18} />
                            )}
                          </>
                        ) : (
                          <IoMdNotificationsOutline onClick={handleOpenNotificationPopup} size={18} />
                        )}
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>

                {isCardMoving && cardMovingId === task?.id && (
                  <div
                    className='position-absolute top-50 start-50 translate-middle backdrop-blur d-flex align-items-center flex-column justify-content-center'
                    style={{ backdropFilter: 'blur(4px)', padding: '20px', width: '100%', height: '100%' }}
                  >
                    <Spinner animation='grow' variant='light' />
                  </div>
                )}
              </Card.Body>
            </Card>
          </div>
        )}
      </Draggable>
      <KanbanModal show={show} handleToggle={handleToggle} selectedTask={selectedTask} />
      <AdminClientModal
        show={open}
        handleClose={handleCloseModal}
        projectDetail={projectDetail ?? task}
        selectedPipeLine={selectedPipeLine}
        task={task}
        setOpenClientModal={setOpen}
      />
      <TaskDeleteModal openTaskDelete={openTaskDelete} handleCloseTaskDelete={handleCloseTaskDelete} task={task} />

      <NotificationPopup
        openNotificationPopup={openNotificationPopup}
        handleCloseNotificationPopup={handleCloseNotificationPopup}
        task={task}
      />
    </>
  )
}

export default TaskCard
