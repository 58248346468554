import React, { useEffect, useState } from 'react'
import { Col, Row, Card, Tooltip, OverlayTrigger } from 'react-bootstrap'
// import {Select} from 'react-bootstrap'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import {
  getAllPipelines,
  getAllOpportunities,
  addProjectsSettings,
  editProjectsSettings,
  UplaodFile,
  getAllProjectsSettings
} from '../../../redux/projects/action'
import _ from 'lodash'
import { updateOpportunities } from '../../../redux/projects/action'
import { IoMdClose } from 'react-icons/io'
import { useDropzone } from 'react-dropzone'
import Flex from 'components/common/Flex'
import cloudUpload from 'assets/img/icons/cloud-upload.svg'
import { renderDocsView } from 'utils/constants'
import { IoClose } from 'react-icons/io5'
import { getAllUsers } from '../../../redux/users/action'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'

let validations = Yup.object({
  // noOfDev: Yup.number().required('Required').min(1, 'Must be greater than zero'),
  // skills: Yup.array().min(1, 'At least one skill is required'),
  price: Yup.string()
    .matches(/^\d+$/, 'Price must contain only digits')
    .required('Price is Required.')
    .max(10, 'Price must be safe number'),
  //   assignments: Yup.array().of(
  //     Yup.object().shape({
  //       skill_id: Yup.string().required('Skill is Required.'),
  //       budget: Yup.string().required('Budget is Required.')
  //     })
  //   ),
  videoUrl: Yup.array().of(
    Yup.object().shape({
      title: Yup.string(),
      url: Yup.string().test({
        test: function (value) {
          const title = this.parent.title
          // If both title and url are filled, return true to pass the test
          if (title && title.trim() !== '' && value && value.trim() !== '') {
            return true
          }
          // If title is not empty but url is empty, return false
          if (title && title.trim() !== '') {
            return false
          }
          // If title is empty, return true
          return true
        },
        message: 'URL is Required.'
      })
    })
  ),
  notes: Yup.array().of(
    Yup.object().shape({
      title: Yup.string(),
      description: Yup.string().test({
        test: function (value) {
          const title = this.parent.title
          // If both title and url are filled, return true to pass the test
          if (title && title.trim() !== '' && value && value.trim() !== '') {
            return true
          }
          // If title is not empty but url is empty, return false
          if (title && title.trim() !== '') {
            return false
          }
          // If title is empty, return true
          return true
        },
        message: 'Description is Required.'
      })
    })
  )
})

const ProjectSettingsDb = ({ projectDetailValues }) => {
  const dispatch = useDispatch()
  const { jobDetail, usersList, skills, isJobDetail } = useSelector(state => state.users)
  const { userDetails } = useSelector(state => state.login)
  const { isProjectSettings, PorjectSettings, pipelines } = useSelector(state => state.projects)
  const { savedPipleline } = useSelector(state => state.calenders)
  const [textIndex, setTextIndex] = useState(0)
  const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false)
  const [isSubmittingSettings, setIsSubmittingSettings] = useState(false)
  const [files, setFiles] = useState([])
  const [skillError, setSkillError] = useState([])
  const [budgetError, setBudgetError] = useState([])
  const [ImgLoading, setImgLoading] = useState(false)
  const [permissionsArr, setPermissionArr] = useState([
    { id: 'sms_chat_enabled', name: 'Sms', value: 0 },
    { id: 'email_chat_enabled', name: 'Email', value: 0 },
    { id: 'team_chat_enabled', name: 'Team Chat', value: 0 }
  ])

  let selectedPipeLine = pipelines?.find(item => item?.id == savedPipleline?.pipelineId)
  let isOnMaintenance =
    jobDetail?.pipelineStageId === selectedPipeLine?.stages[selectedPipeLine?.stages?.length - 2]?.id ||
    jobDetail?.pipelineStageId === selectedPipeLine?.stages[selectedPipeLine?.stages?.length - 1]?.id

  let isMoveToInprogress =
    jobDetail?.pipelineStageId === selectedPipeLine?.stages[0]?.id ||
    jobDetail?.pipelineStageId === selectedPipeLine?.stages[1]?.id

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    // accept: {
    //   'image/png': ['.png'],
    //   'image/jpeg': ['.jpg', '.jpeg'],
    //   'application/msword': ['.doc'],
    //   'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    //   'application/vnd.ms-excel': ['.xls'],
    //   'application/pdf': ['.pdf'],
    //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    // },
    onDrop: async acceptedFiles => {
      // Determine the maximum file size based on file type.

      const maxFileSizeBytes =
        acceptedFiles[0].type.startsWith('application/') || acceptedFiles[0].type.startsWith('image/')
          ? 10 * 1024 * 1024
          : 0

      // Check if the file size exceeds the maximum limit and display a toast message if so.
      if (acceptedFiles[0].size > maxFileSizeBytes) {
        if (acceptedFiles[0].type.startsWith('application/') || acceptedFiles[0].type.startsWith('image/')) {
          return toast.error('File size exceeds the maximum limit (10MB)', {})
        }
      }

      // Set image loading state to true.
      setImgLoading(true)
      const formData = new FormData()
      formData.append('file', acceptedFiles[0])

      try {
        // Upload the file using the 'uploadInnerDocuments' API.
        const response = await UplaodFile(formData)
        if (response?.data?.status_code == 201 || response?.data?.status_code == 200) {
          setImgLoading(false)
          setFiles([
            ...files,
            {
              id: uuidv4(),
              title: '',
              url: response?.data?.data?.file,
              source: 'requirements',
              created_by: userDetails?._id,
              date: dayjs().valueOf()
            }
          ])
          toast.success(response?.data.message, {
            position: 'top-right',
            theme: 'colored'
          })
        } else {
          setImgLoading(false)
        }
        // Check the response and extract the image URL.
      } catch (error) {
        console.error('Error:', error)
        setImgLoading(false)
      }
    }
  })

  const handleClose = () => {
    setIsOpenScheduleModal(!isOpenScheduleModal)
  }

  const fetchDataForPipelines = async () => {
    try {
      // Create an array of promises for fetching data for each pipeline
      const promises = selectedPipeLine?.stages?.map(stage => {
        // Dispatch the action to fetch data for each pipeline
        dispatch(
          getAllOpportunities({ pipeline_id: savedPipleline?.pipelineId, limit: 20, pipeline_stage_id: stage?.id })
        )
      })

      // Wait for all promises to resolve
      await Promise.all(promises)
    } catch (error) {
      console.error('Admin Error fetching data:', error)
    }
  }
  const formik = useFormik({
    initialValues: {
      documents: [],
      videoUrl: [
        {
          id: uuidv4(),
          title: '',
          url: '',
          source: 'requirements',
          created_by: userDetails?._id,
          date: dayjs().valueOf()
        }
      ],
      notes: [
        {
          id: uuidv4(),
          title: '',
          description: '',
          source: 'requirements',
          created_by: userDetails?._id,
          date: dayjs().valueOf()
        }
      ],

      descriptions: '',
      price: '',
      assignments: [
        {
          skill_id: '',
          assignee_id: '',
          budget: '',
          assigned_by: userDetails?._id,
          assignDate: 0,
          unassignDate: 0 //if admin assign only that time send value ==>  Math.floor(new Date().getTime() / 1000)
        }
      ],
      isInMaintenance: 0
    },
    validationSchema: validations,
    onSubmit: async (values, { setSubmitting }) => {
      let skillError = []
      let budgetError = []

      values.assignments.forEach((assignment, index) => {
        if (!assignment.skill_id) {
          skillError.push(`Skill is Required`)
        } else {
          skillError.push('')
        }
      })
      values.assignments.forEach((assignment, index) => {
        if (!assignment.budget) {
          budgetError.push(`Budget is Required`)
        } else {
          budgetError.push('')
        }
      })
      setSkillError(skillError)
      setBudgetError(budgetError)
      const skillRequiredError = skillError.some(error => error.includes('Skill is Required'))
      const budgetRequiredError = budgetError.some(error => error.includes('Budget is Required'))
      const filteredVideo = values.videoUrl.filter(video => video?.url != '') ?? []
      const filteredNotes = values.notes.filter(note => note?.description != '') ?? []
      let conversationDocs =
        PorjectSettings?.documents
          ?.filter(item => item?.source !== 'requirements')
          ?.map(item => {
            const { createdByName, ...rest } = item
            return rest
          }) ?? []
      let conversationVideos = PorjectSettings?.videoUrl
        ?.filter(item => item?.source !== 'requirements')
        ?.map(item => {
          const { createdByName, ...rest } = item
          return rest
        })
      let conversationNotes = PorjectSettings?.notes
        ?.filter(item => item?.source !== 'requirements')
        ?.map(item => {
          const { createdByName, ...rest } = item
          return rest
        })

      try {
        if (!skillRequiredError && !budgetRequiredError) {
          let params = {
            name: jobDetail?.name ?? '',
            projectId: jobDetail?.id ?? '',
            videoUrl: filteredVideo[0]?.url ? [...conversationVideos, ...filteredVideo] : [] ?? [],
            descriptions: values.descriptions ?? '',
            documents: [...conversationDocs, ...files] ?? [],
            assignments: values.assignments ?? [],
            price: values.price ?? '',
            notes: filteredNotes[0]?.description ? [...conversationNotes, ...filteredNotes] : [] ?? [],
            sms_chat_enabled: permissionsArr[0].value ?? 1,
            email_chat_enabled: permissionsArr[1].value ?? 1,
            team_chat_enabled: permissionsArr[2].value ?? 1,
            isInMaintenance: values.isInMaintenance ?? 0
          }
          setIsSubmittingSettings(true)

          let resSettings
          if (PorjectSettings) {
            params.isWaiting = isOnMaintenance ? 1 : 0
            resSettings = await editProjectsSettings(params)
          } else {
            resSettings = await addProjectsSettings(params)
          }

          if (resSettings?.data?.status_code === 200 || resSettings?.data?.status_code === 201) {
            toast.success(resSettings?.data.message, {
              position: 'top-right',
              theme: 'colored'
            })

            // if (resSettings?.data?.isAbleToProceed && isMoveToInprogress) {
            //   let apiParams = {
            //     opportunities_id: jobDetail?.id,
            //     pipelineId: savedPipleline?.pipelineId,
            //     pipelineStageId: selectedPipeLine?.stages[2]?.id
            //   }
            //   updateOpportunities(apiParams)
            // }
            setTimeout(() => {
              setIsSubmittingSettings(false)
              dispatch(getAllUsers())
              dispatch({ type: 'REMOVE_ALL_OPPORTUNITIES' })
              dispatch(getAllPipelines())
              dispatch(getAllProjectsSettings({ pipelineId: savedPipleline?.pipelineId }))
              fetchDataForPipelines()
              handleClose()
              formik.resetForm()
            }, 1000)
          } else {
            toast.error(resSettings?.data?.message, {
              position: 'top-right',
              theme: 'colored'
            })
            setIsSubmittingSettings(false)
          }
        }
      } catch (error) {
        console.error('An error occurred:', error)
        toast.error('An error occurred. Please try again.', {
          position: 'top-right',
          theme: 'colored'
        })
        setIsSubmittingSettings(false)
      }
    }
  })

  useEffect(() => {
    if (PorjectSettings) {
      let filteredPermissions = [...permissionsArr]
      filteredPermissions[0].value = PorjectSettings?.sms_chat_enabled ?? 1
      filteredPermissions[1].value = PorjectSettings?.email_chat_enabled ?? 1
      filteredPermissions[2].value = PorjectSettings?.team_chat_enabled ?? 1

      setPermissionArr(filteredPermissions)

      let adminNotes = PorjectSettings?.notes
        ?.filter(item => item.source === 'requirements')
        .map(item => {
          const { createdByName, ...rest } = item
          return rest
        })

      let adminDocs = PorjectSettings?.documents
        ?.filter(item => item.source === 'requirements')
        .map(item => {
          const { createdByName, ...rest } = item
          return rest
        })

      let adminVideos = PorjectSettings?.videoUrl
        ?.filter(item => item.source === 'requirements')
        .map(item => {
          const { createdByName, ...rest } = item
          return rest
        })

      let initialValues = {
        documents: adminDocs?.length > 0 ? adminDocs : [],
        videoUrl:
          adminVideos?.length > 0
            ? adminVideos
            : [
                {
                  id: uuidv4(),
                  title: '',
                  url: '',
                  source: 'requirements',
                  created_by: userDetails?._id,
                  date: dayjs().valueOf()
                }
              ],
        notes:
          adminNotes?.length > 0
            ? adminNotes
            : [
                {
                  id: uuidv4(),
                  title: '',
                  description: '',
                  source: 'requirements',
                  created_by: userDetails?._id,
                  date: dayjs().valueOf()
                }
              ],
        descriptions: PorjectSettings?.descriptions,
        assignments:
          PorjectSettings?.assignments?.length > 0
            ? PorjectSettings?.assignments
            : [
                {
                  skill_id: '',
                  assignee_id: '',
                  budget: '',
                  assigned_by: userDetails?._id,
                  assignDate: 0,
                  unassignDate: 0 //if admin assign only that time send value ==>  Math.floor(new Date().getTime() / 1000)
                }
              ],
        isInMaintenance: PorjectSettings?.isInMaintenance ?? 0,
        price: PorjectSettings?.price
      }
      setFiles(adminDocs ?? [])
      formik.setValues(initialValues)
    } else {
      let initialValues = {
        documents: [],
        videoUrl: [
          {
            id: uuidv4(),
            title: '',
            url: '',
            source: 'requirements',
            created_by: userDetails?._id,
            date: dayjs().valueOf()
          }
        ],
        descriptions: '',
        price: '',
        assignments: [
          {
            skill_id: '',
            assignee_id: '',
            budget: '',
            assigned_by: userDetails?._id,
            assignDate: 0,
            unassignDate: 0
          }
        ],
        notes: [
          {
            id: uuidv4(),
            title: '',
            description: '',
            source: 'requirements',
            created_by: userDetails?._id,
            date: dayjs().valueOf()
          }
        ]
      }
      setFiles([])
      formik.setValues(initialValues)
    }
  }, [PorjectSettings])

  const handleAddMore = () => {
    formik.setValues({
      ...formik.values,
      assignments: [
        ...formik.values.assignments,
        {
          skill_id: '',
          assignee_id: '',
          budget: '',
          assigned_by: userDetails?._id,

          assignDate: 0,
          unassignDate: 0
        }
      ]
    })
  }

  const handleAddMoreLinks = () => {
    formik.setValues({
      ...formik.values,
      videoUrl: [
        ...formik.values.videoUrl,
        {
          id: uuidv4(),

          title: '',
          url: '',
          source: 'requirements',
          created_by: userDetails?._id,
          date: dayjs().valueOf()
        }
      ]
    })
  }
  const handleAddMoreNotes = () => {
    formik.setValues({
      ...formik.values,
      notes: [
        ...formik.values.notes,
        {
          id: uuidv4(),

          title: '',
          description: '',
          source: 'requirements',
          created_by: userDetails?._id,
          date: dayjs().valueOf()
        }
      ]
    })
  }
  const handleRemove = indexToRemove => {
    let append = [...formik.values.assignments]
    const newSkillError = [...skillError]
    const newBudgetError = [...budgetError]
    append.splice(indexToRemove, 1)
    newSkillError.splice(indexToRemove, 1)
    newBudgetError.splice(indexToRemove, 1)
    formik.setFieldValue('assignments', append)
    setSkillError(newSkillError)
    setBudgetError(newBudgetError)
  }

  const handleRemoveLinks = indexToRemove => {
    formik.setValues({
      ...formik.values,
      videoUrl: formik.values.videoUrl.filter((_, index) => index !== indexToRemove)
    })
  }
  const handleRemoveNotes = indexToRemove => {
    formik.setValues({
      ...formik.values,
      notes: formik.values.notes.filter((_, index) => index !== indexToRemove)
    })
  }
  const handleAssignmentChange = (index, fieldName, value) => {
    const newSkillError = [...skillError]
    const newBudgetError = [...budgetError]

    setTextIndex(index)

    const updatedAssignments = [...formik.values.assignments]
    updatedAssignments[index] = {
      ...updatedAssignments[index],
      [fieldName]: value
    }
    formik.setFieldValue('assignments', updatedAssignments)
    if (fieldName == 'skill_id') {
      if (value) {
        if (updatedAssignments[index].skill_id) {
          newSkillError[index] = ''
        }
      } else {
        newSkillError[index] = 'Skill is Required'
      }
    }
    if (fieldName == 'budget') {
      if (value) {
        if (updatedAssignments[index].budget) {
          newBudgetError[index] = ''
        }
      } else {
        newBudgetError[index] = 'Budget is Required'
      }
    }
    setSkillError(newSkillError)
    setBudgetError(newBudgetError)
  }

  const handleVideoUrlChange = (index, fieldName, value) => {
    const updatedVideoUrls = [...formik.values.videoUrl]
    updatedVideoUrls[index] = {
      ...updatedVideoUrls[index],
      [fieldName]: value
    }
    formik.setFieldValue('videoUrl', updatedVideoUrls)
  }
  const handleNotesChange = (index, fieldName, value) => {
    const updatedNotesUrls = [...formik.values.notes]
    updatedNotesUrls[index] = {
      ...updatedNotesUrls[index],
      [fieldName]: value
    }
    formik.setFieldValue('notes', updatedNotesUrls)
  }

  let sumOfBudget = formik.values.assignments.reduce(
    (accumulator, currentValue) => accumulator + currentValue.budget,
    0
  )
  let isGreaterBudget = sumOfBudget > formik.values.price
  const handleRemoveFile = rmDoc => {
    let filteredDocs = files?.filter(item => item?.url !== rmDoc)
    setFiles(filteredDocs)
  }

  const handleUpdateDocTitle = (index, fieldName, value) => {
    const updatedFiles = [...files]
    updatedFiles[index] = {
      ...updatedFiles[index],
      [fieldName]: value
    }
    setFiles(updatedFiles)
  }

  const fileList = files?.map((file, index) => {
    return (
      <div key={index}>
        <div className='d-flex align-items-center gap-3'>
          <div className='d-inline-block position-relative'>
            {renderDocsView(file?.url)}
            <span
              className='position-absolute d-flex flex-column align-items-center justify-content-between '
              style={{ width: '20px', height: '20px', top: -10, borderRadius: '50%', right: -10, border: 'none' }}
            >
              <span className='cursor-pointer' onClick={() => handleRemoveFile(file?.url)}>
                <IoMdClose />
              </span>
            </span>
          </div>
          <div style={{ width: '300px' }}>
            <Form.Control
              type='text'
              value={file?.title}
              name='title'
              placeholder='Title'
              onChange={e => handleUpdateDocTitle(index, 'title', e.target.value)}
            />
          </div>
        </div>

        <div></div>
      </div>
    )
  })

  const handleToggle = (check, index, name) => {
    const filterArr = [...permissionsArr]
    filterArr[index].value = check === true ? 1 : 0
    setPermissionArr(filterArr)
  }

  return (
    <>
      <Card className='py-4'>
        <Form className='position-relative' noValidate onSubmit={formik.handleSubmit}>
          <Row>
            <Col sm={12}>
              <div className='px-3'>
                <p className='fs-8 fw-bolder'>Project Requirements</p>
                <Row>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                    <p className='fs-10 fw-bolder mb-1'>Description</p>
                    <Form.Control
                      value={formik.values.descriptions}
                      name='descriptions'
                      placeholder='Type a description'
                      onChange={formik.handleChange}
                      as='textarea'
                      rows={3}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                    <p className='fs-10 fw-bolder mb-1'>Price</p>
                    <Form.Control
                      //   type='number'
                      name='price'
                      placeholder='Price'
                      value={formik.values.price}
                      style={{ height: '38px' }}
                      onChange={e => {
                        const value = parseFloat(e.target.value)
                        if (!isNaN(value) && value >= 0) {
                          formik.setFieldValue('price', value)
                        } else {
                          formik.setFieldValue('price', '')
                        }
                        formik.setFieldTouched('price', true)
                      }}
                      isInvalid={formik.touched.price && formik.errors.price}
                    />
                    <Form.Control.Feedback type='invalid'>{formik.errors.price}</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <p className='fs-10 fw-bolder mb-1 mt-3'>Skills</p>
                  {formik?.values?.assignments?.map((item, index) => {
                    return (
                      <>
                        <Col md={3} key={index}>
                          <Form.Group className='mb-3' controlId='titleInput'>
                            <Select
                              getOptionLabel={option => option?.name}
                              getOptionValue={option => option?._id}
                              isClearable
                              value={skills.find(obj => item?.skill_id === obj._id) ?? ''}
                              options={skills?.filter(
                                skillObj =>
                                  !formik.values.assignments.some(assignment => assignment.skill_id === skillObj._id) ??
                                  []
                              )}
                              placeholder={'Add Skill'}
                              classNamePrefix='react-select'
                              onChange={selectedOptions => {
                                handleAssignmentChange(index, 'skill_id', selectedOptions?._id)
                                // handleAssignmentChange(index, 'assignee_id', '')
                              }}
                              // onBlur={formik.handleBlur}
                            />
                            {skillError[index] ? (
                              <span style={{ color: '#e63757', fontSize: '12px', fontWeight: '600' }}>
                                {' '}
                                {skillError[index]}
                              </span>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col md={4} key={index}>
                          <Form.Group className='mb-3' controlId='label'>
                            <Form.Control
                              //   type='number'
                              name='budget'
                              placeholder='Price/Commission'
                              value={item?.budget}
                              style={{ height: '38px' }}
                              onChange={e => {
                                const value = e.target.value.trim()
                                if (value === '' || (!isNaN(parseFloat(value)) && parseFloat(value) >= 0)) {
                                  handleAssignmentChange(index, 'budget', value === '' ? '' : parseFloat(value))
                                }
                              }}
                            />
                            {index === textIndex && formik.values.price > 0 && isGreaterBudget && (
                              <span className='fs-10 text-danger'>Total Price is higher than actual budget</span>
                            )}
                            {budgetError[index] ? (
                              <span style={{ color: '#e63757', fontSize: '12px', fontWeight: '600' }}>
                                {' '}
                                {budgetError[index]}
                              </span>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col md={4} key={index}>
                          <Form.Group className='mb-3  position-relative' controlId='titleInput'>
                            <Select
                              getOptionLabel={option => option?.name}
                              getOptionValue={option => option?.id}
                              isClearable
                              isDisabled={item?.skill_id ? false : true}
                              value={usersList.find(obj => item?.assignee_id === obj.id) ?? ''}
                              options={usersList.filter(
                                user =>
                                  user?.skills?.includes(item?.skill_id) &&
                                  user?.assignProjects < user?.noOfProjectAssign &&
                                  user?.status === 1
                              )}
                              placeholder={'Add Developer'}
                              classNamePrefix='react-select'
                              onChange={selectedOptions => {
                                handleAssignmentChange(index, 'assignee_id', selectedOptions?.id ?? '')
                              }}
                              //   onBlur={formik.handleBlur}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={1}>
                          {formik.values.assignments?.length > 1 && index !== 0 && (
                            <span
                              style={{
                                backgroundColor: '#ed6d6d',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                height: '25px',
                                width: '25px',
                                borderRadius: '50%',
                                color: '#fff'
                              }}
                            >
                              {/* <span onClick={() => handleRemove(index)} className='cursor-pointer '><IoMdClose /></span> */}
                              <IoClose onClick={() => handleRemove(index)} size={20} />
                            </span>
                          )}
                        </Col>
                      </>
                    )
                  })}

                  <div className='d-flex justify-content-end mb-3'>
                    <Button onClick={handleAddMore} style={{ backgroundColor: '#000', border: '1px solid #000' }}>
                      Add Skills
                    </Button>
                  </div>
                </Row>

                <Row className=''>
                  <p className='fs-10 fw-bolder mb-1'>Upload Documents</p>
                  <Col>
                    <div {...getRootProps({ className: 'dropzone-area py-4 ' })}>
                      <input {...getInputProps({ multiple: false })} disabled={ImgLoading} />
                      <Flex justifyContent='center'>
                        {ImgLoading ? (
                          <Spinner size='md' />
                        ) : (
                          <>
                            <img src={cloudUpload} alt='' width={25} className='me-2' />
                            <p className='fs-9 mb-0 text-700'>Drop your Docs here</p>
                          </>
                        )}
                      </Flex>
                    </div>
                  </Col>
                  <div className='mt-3 d-flex mb-4 gap-3 flex-wrap'>{fileList}</div>
                </Row>
                <Row>
                  <p className='fs-10 fw-bolder mb-2 mt-3'>Video Urls</p>
                  {formik.values.videoUrl.map((item, index) => {
                    const videoError = formik?.errors?.videoUrl?.[index]

                    return (
                      <>
                        <Col md={6} key={index}>
                          <Form.Group className='mb-4 position-relative ' controlId='label'>
                            {index == 0 && <p className='fs-10 fw-bolder mb-1'>Title</p>}

                            <Form.Control
                              type='text'
                              name='title'
                              placeholder='Title'
                              value={item.title}
                              onChange={e => handleVideoUrlChange(index, 'title', e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6} key={index}>
                          <Form.Group className='mb-4 position-relative ' controlId='label'>
                            {index == 0 && <p className='fs-10 fw-bolder mb-1'>Video Urls</p>}

                            <Form.Control
                              type='text'
                              name='url'
                              placeholder='https://'
                              value={item.url}
                              onChange={e => handleVideoUrlChange(index, 'url', e.target.value)}
                            />
                            {formik.values.videoUrl?.length > 1 && index !== 0 && (
                              <span
                                className='position-absolute d-flex flex-column align-items-center justify-content-between me-3'
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  top: -25,
                                  borderRadius: '50%',
                                  right: -20,
                                  border: 'none'
                                }}
                              >
                                <span onClick={() => handleRemoveLinks(index)} className='cursor-pointer '>
                                  <IoMdClose />
                                </span>
                              </span>
                            )}
                            {formik.touched.videoUrl && formik.errors.videoUrl && videoError && videoError.url ? (
                              <span style={{ color: '#e63757', fontSize: '12px', fontWeight: '600' }}>
                                {' '}
                                {videoError.url}
                              </span>
                            ) : null}
                          </Form.Group>
                        </Col>
                      </>
                    )
                  })}

                  <div className='d-flex justify-content-end mb-3'>
                    <Button onClick={handleAddMoreLinks} style={{ backgroundColor: '#000', border: '1px solid #000' }}>
                      Add More Links
                    </Button>
                  </div>
                </Row>

                {/******************  Notes *********************/}

                <Row>
                  <p className='fs-10 fw-bolder mb-2 mt-3'>Notes</p>
                  {formik.values.notes?.map((item, index) => {
                    const notesError = formik?.errors?.notes?.[index]

                    return (
                      <>
                        <Col md={6} key={index}>
                          <Form.Group className='mb-4 position-relative ' controlId='label'>
                            {index == 0 && <p className='fs-10 fw-bolder mb-1'>Title</p>}

                            <Form.Control
                              type='text'
                              name='title'
                              placeholder='Title'
                              value={item.title}
                              onChange={e => handleNotesChange(index, 'title', e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6} key={index}>
                          <Form.Group className='mb-4 position-relative ' controlId='label'>
                            {index == 0 && <p className='fs-10 fw-bolder mb-1'>Description</p>}

                            <Form.Control
                              type='text'
                              name='description'
                              placeholder='description..'
                              value={item.description}
                              onChange={e => handleNotesChange(index, 'description', e.target.value)}
                            />
                            {formik.values.notes?.length > 1 && index !== 0 && (
                              <span
                                className='position-absolute d-flex flex-column align-items-center justify-content-between me-3'
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  top: -25,
                                  borderRadius: '50%',
                                  right: -20,
                                  border: 'none'
                                }}
                              >
                                <span onClick={() => handleRemoveNotes(index)} className='cursor-pointer '>
                                  <IoMdClose />
                                </span>
                              </span>
                            )}
                            {formik.touched.notes && formik.errors.notes && notesError && notesError.description ? (
                              <span style={{ color: '#e63757', fontSize: '12px', fontWeight: '600' }}>
                                {' '}
                                {notesError.description}
                              </span>
                            ) : null}
                          </Form.Group>
                        </Col>
                      </>
                    )
                  })}

                  <div className='d-flex justify-content-end mb-3'>
                    <Button onClick={handleAddMoreNotes} style={{ backgroundColor: '#000', border: '1px solid #000' }}>
                      Add More Notes
                    </Button>
                  </div>
                </Row>

                <Row className='mb-2'>
                  <p className='fs-10 fw-bolder mb-2 mt-3'>Permissions</p>
                  {permissionsArr?.map((item, index) => {
                    return (
                      <>
                        <Col md={3} key={index} style={{ display: 'flex', gap: '20px' }}>
                          <label className='form-check-label' htmlFor={`toggleSwitch${index}`}>
                            {item?.name}
                          </label>

                          <div className='form-check form-switch'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id={`toggleSwitch${index}`}
                              checked={item?.value == 1 ? true : false}
                              onChange={event => {
                                handleToggle(event.target.checked, index, item?.name)
                              }}
                              style={{ cursor: 'pointer' }}
                            />
                          </div>
                        </Col>
                      </>
                    )
                  })}
                </Row>

                <Row>
                  <Col md={6} style={{ display: 'flex', gap: '20px' }}>
                    <label className='form-check-label'>Move to In-Progress</label>

                    <div className='form-check form-switch'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={formik.values.isInMaintenance == 1 ? true : false}
                        onChange={event => {
                          formik.setFieldValue('isInMaintenance', event.target.checked == true ? 1 : 0)
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  </Col>
                </Row>

                <div className='d-flex justify-content-end'>
                  <Button
                    disabled={isSubmittingSettings || formik.values.price <= 0 || isGreaterBudget}
                    variant='primary'
                    type='submit'
                    onClick={handleClose}
                    className='px-4 mx-0'
                  >
                    {isSubmittingSettings ? <Spinner size='sm' /> : 'Save'}
                  </Button>

                  {isProjectSettings || isJobDetail ? (
                    <div
                      className='position-absolute top-0'
                      style={{
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backdropFilter: 'blur(3px)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Spinner />
                    </div>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  )
}

export default ProjectSettingsDb
