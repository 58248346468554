import React, { useState, useEffect } from 'react'
import { Card, OverlayTrigger, Tooltip, Badge, Spinner } from 'react-bootstrap'
import { AiOutlineMessage } from 'react-icons/ai'
import SubtleBadge from 'components/common/SubtleBadge'
import { PiNotebookThin } from 'react-icons/pi'
import { useDispatch, useSelector } from 'react-redux'
import { Encrypt } from 'utils/constants'
import { ListionUnreadMessage } from 'utils/socket'
import { useNavigate } from 'react-router-dom'
import GroupMember from '../GroupAvatar'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { IoVideocamOutline } from 'react-icons/io5'
import { formatBudget } from 'utils/constants'
import _ from 'lodash'
import { Draggable } from 'react-beautiful-dnd'
import NotificationPopup from '../projectDetailViews/NotificationPopup'
import { IoMdNotificationsOutline } from 'react-icons/io'
import { MdOutlineNotificationsOff } from 'react-icons/md'

const AssignedCard = ({ job, index }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { allProjectSettings, isCardMoving, cardMovingId } = useSelector(state => state.projects)
  let selectedProjectSetting = allProjectSettings?.find(item => item?.projectId === job?.id)
  const { allUnreadCounts } = useSelector(state => state.groupChat)
  const [totalUnreadCount, setTotalUnreadCount] = useState(0)
  const [projectDetail, setProjectDetail] = useState(null)
  const [openNotificationPopup, setOpenNotificationPopup] = useState(false)

  useEffect(() => {
    let ChatCountListing = allUnreadCounts?.filter(item => item?.room_id === job?.id) ?? []
    let clientChatUnreadCount = ChatCountListing?.find(item => item?.type === 'client')?.count ?? 0
    let teamChatUnreadCount = ChatCountListing?.find(item => item?.type === 'team')?.count ?? 0
    setTotalUnreadCount(
      isNaN(clientChatUnreadCount + teamChatUnreadCount) ? 0 : clientChatUnreadCount + teamChatUnreadCount ?? 0
    )
  }, [allUnreadCounts])

  useEffect(() => {
    if (selectedProjectSetting) {
      const updatedAssignments = selectedProjectSetting?.assignments.map(assignment => {
        const skillDetail = _.find(selectedProjectSetting?.skillsDetails, { _id: assignment?.skill_id })
        if (skillDetail) {
          return {
            ...assignment,
            name: skillDetail.name
          }
        }
        return assignment
      })
      const updatedDataObject = {
        ...selectedProjectSetting,
        assignments: updatedAssignments
      }
      setProjectDetail(updatedDataObject)
      ListionUnreadMessage(dispatch)
    }
  }, [allProjectSettings])

  const getItemStyle = isDragging => ({
    cursor: isDragging ? 'grabbing' : 'pointer',
    transform: isDragging ? 'rotate(-2deg)' : '',
    backgroundColor: isDragging ? '#F6F1FF' : '#fff'
  })

  const handleRedirect = (title, isNotification) => {
    if (isNotification) {
      handleOpenNotificationPopup()
    } else {
      dispatch({ type: 'REMOVE_STATES' })
      navigate(`/app/projects/${Encrypt(job?.id)}/detail`)
      if (title && !isNotification) {
        dispatch({ type: 'TOGGLE_DETAIL_MODAL', payload: { show: true, title: title } })
      }
    }
  }

  const handleOpenNotificationPopup = () => {
    setOpenNotificationPopup(true)
  }

  const handleCloseNotificationPopup = () => {
    setOpenNotificationPopup(false)
  }

  let isAssingedSkills = projectDetail?.assignments?.some(item => item.assignee_id)
  let unAssingedSkills = projectDetail?.assignments?.some(item => !item.assignee_id)
  return (
    <>
      <Draggable
        draggableId={`${job?.id}`}
        index={index}
        // isDragDisabled={
        //     userType === 2 ||
        //         job.pipelineStageId === selectedPipeLine?.stages[selectedPipeLine?.stages?.length - 2]?.id
        //         ? true
        //         : false
        // }
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={provided.draggableProps.style}
            className='kanban-item'
          >
            <Card
              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
              className='kanban-item-card hover-actions-trigger'
            >
              <Card.Body
                style={{
                  backgroundColor: isCardMoving && cardMovingId === job?.id ? '#F6F1FF' : '',
                  position: 'relative'
                }}
              >
                <>
                  <div onClick={() => handleRedirect()}>
                    <div className='d-flex justify-content-between w-100'>
                      <div>
                        {job?.name && (
                          <p
                            className='mb-0 fw-medium font-sans-serif'
                            style={{ whiteSpace: 'wrap', overflow: 'hidden', wordBreak: 'break-all' }}
                          >
                            {job?.name.length > 80 ? job?.name.substring(0, 80) + '...' : job?.name}
                          </p>
                        )}

                        {projectDetail?.descriptions && (
                          <p
                            className='mb-0 fw-light font-sans-serif  fs-11'
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              width: '180px'
                            }}
                          >
                            {projectDetail?.descriptions}
                          </p>
                        )}
                      </div>
                      <GroupMember
                        avatarSize='l'
                        users={selectedProjectSetting?.assignedDev}
                        showMember={2}
                        className='d-none d-sm-block'
                      />
                    </div>
                    <div className='mt-2'>
                      {isAssingedSkills && (
                        <div>
                          <p
                            style={{
                              color: '#000',
                              fontWeight: '500',
                              fontSize: '12px',
                              marginBottom: '5px',
                              marginTop: '5px'
                            }}
                          >
                            Assigned:
                          </p>
                          <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
                            {projectDetail?.assignments?.map((item, index) => {
                              if (item?.assignee_id) {
                                return (
                                  <SubtleBadge
                                    key={item?.skill_id}
                                    className='py-2 me-1 mb-1 text-dark d-flex gap-1 align-items-center'
                                  >
                                    <div
                                      style={{
                                        width: '8px',
                                        height: '8px',
                                        backgroundColor: '#2FCC68',
                                        borderRadius: '50px'
                                      }}
                                    ></div>
                                    {item?.name}
                                  </SubtleBadge>
                                )
                              }
                            })}
                          </div>
                        </div>
                      )}
                      {unAssingedSkills && (
                        <div>
                          <p
                            style={{
                              color: '#000',
                              fontWeight: '500',
                              fontSize: '12px',
                              marginBottom: '5px',
                              marginTop: '5px'
                            }}
                          >
                            Pending:
                          </p>
                          <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
                            {projectDetail?.assignments?.map((item, index) => {
                              if (!item?.assignee_id) {
                                return (
                                  <>
                                    <SubtleBadge
                                      key={item?.skill_id}
                                      className='py-2 me-1 mb-1 text-dark d-flex gap-1 align-items-center'
                                    >
                                      <div
                                        style={{
                                          width: '8px',
                                          height: '8px',
                                          backgroundColor: '#FDD835',
                                          borderRadius: '50px'
                                        }}
                                      ></div>
                                      {item?.name}
                                    </SubtleBadge>
                                  </>
                                )
                              }
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='d-flex justify-content-between align-item-center border-top mt-1 pt-1'>
                    <h6 style={{ fontWeight: '600' }} className='mb-0 font-sans-serif fs-10 mt-2'>
                      ${formatBudget(selectedProjectSetting?.price, 2)}
                    </h6>

                    <div className='d-flex align-items-center gap-3'>
                      <OverlayTrigger
                        placement='top'
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id='call'>Conversations</Tooltip>}
                      >
                        <div className='position-relative' id='call'>
                          <AiOutlineMessage onClick={() => handleRedirect()} size={18} />
                          {totalUnreadCount > 0 && (
                            <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>
                              {totalUnreadCount < 100 ? totalUnreadCount : '99+'}
                            </span>
                          )}
                        </div>
                      </OverlayTrigger>
                      <>
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 250, hide: 400 }}
                          overlay={<Tooltip id='call'>Notes</Tooltip>}
                        >
                          <div id='call'>
                            <PiNotebookThin onClick={() => handleRedirect('Notes', false)} size={18} />
                          </div>
                        </OverlayTrigger>
                        <div className='position-relative'>
                          <OverlayTrigger
                            placement='top'
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id='call'>Documents</Tooltip>}
                          >
                            <div id='call'>
                              <IoDocumentTextOutline onClick={() => handleRedirect('Documents', false)} size={18} />
                            </div>
                          </OverlayTrigger>
                        </div>
                      </>

                      <OverlayTrigger
                        placement='top'
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id='call'>Videos</Tooltip>}
                      >
                        <div id='call'>
                          <IoVideocamOutline onClick={() => handleRedirect('Videos', false)} size={18} />
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement='top'
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id='call'>Notifications</Tooltip>}
                      >
                        <div id='call'>
                          {selectedProjectSetting?.notificationSettings ? (
                            <>
                              {!Object.keys(selectedProjectSetting?.notificationSettings)?.length ||
                              selectedProjectSetting?.notificationSettings?.muteAll ? (
                                <MdOutlineNotificationsOff onClick={handleOpenNotificationPopup} size={18} />
                              ) : (
                                <IoMdNotificationsOutline onClick={handleOpenNotificationPopup} size={18} />
                              )}
                            </>
                          ) : (
                            <IoMdNotificationsOutline onClick={handleOpenNotificationPopup} size={18} />
                          )}
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>

                  {isCardMoving && cardMovingId === job?.id && (
                    <div
                      className='position-absolute top-50 start-50 translate-middle backdrop-blur d-flex align-items-center flex-column justify-content-center'
                      style={{ backdropFilter: 'blur(4px)', padding: '20px', width: '100%', height: '100%' }}
                    >
                      <Spinner animation='grow' variant='light' />
                    </div>
                  )}
                </>
              </Card.Body>
            </Card>
          </div>
        )}
      </Draggable>
      <NotificationPopup
        openNotificationPopup={openNotificationPopup}
        handleCloseNotificationPopup={handleCloseNotificationPopup}
        task={job}
      />
    </>
  )
}

export default AssignedCard
