import { ReducerType } from './type'

const InitialState = {
  isGlobalNotification: false,
  error: null,
  success: false,
  globalNotification: null
}

const settings = (state = InitialState, action) => {
  switch (action?.type) {
    case ReducerType.GLOBAL_NOTIFICATION_ACTION_START:
      return {
        ...state,
        success: false,
        error: '',
        isGlobalNotification: true
      }
    case ReducerType.GLOBAL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        globalNotification: action?.payload,
        success: true,
        isGlobalNotification: false
      }
    case ReducerType.GLOBAL_NOTIFICATION_ERROR:
      return {
        ...state,
        success: false,
        isGlobalNotification: false,
        globalNotification: null
      }

    default:
      return state
  }
}
export default settings
