import React, { useState, useEffect } from 'react'
import { Image, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Avatar from 'components/common/Avatar'
import Flex from 'components/common/Flex'
import classNames from 'classnames'
import FalconLightBox from 'components/common/FalconLightBox'
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { IoMdDownload } from 'react-icons/io'
import axios from 'axios'

const Message = ({ message }) => {
  const { jobDetail } = useSelector(state => state.users)
  const isLeft = !message?.userId
  const [width, setWidth] = useState(window.innerWidth)

  const downloadFile = async (url, fileName = 'mtc-files') => {
    try {
      const response = await axios.get(url, {
        responseType: 'blob' // Important
      })

      // Create a blob from the response
      const blob = new Blob([response.data], { type: response.headers['content-type'] })

      // Create a link element to trigger the download
      const downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(blob)
      downloadLink.download = fileName

      // Trigger the download
      downloadLink.click()
    } catch (error) {
      console.error('Error downloading file:', error)
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Flex className={classNames('p-3', { 'd-block': !isLeft })}>
      {isLeft && (
        <OverlayTrigger
          placement='top'
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id='button-tooltip'>{jobDetail?.contact?.name}</Tooltip>}
        >
          <div>
            <Avatar size='l' className='me-2' name={jobDetail?.contact?.name} />
          </div>
        </OverlayTrigger>
      )}
      <div
        className={classNames('flex-1', {
          'd-flex justify-content-end': !isLeft
        })}
      >
        <div
          className={classNames('w-xxl-75', {
            'w-100': !isLeft
          })}
        >
          <Flex
            alignItems='center'
            className={classNames('hover-actions-trigger', {
              'flex-end-center': !isLeft,
              'align-items-center': isLeft
            })}
          >
            {/* {!isLeft && <ChatMessageOptions />} */}
            {message.attachments?.length > 0 ? (
              <div
                style={{
                  backgroundColor: isLeft ? '#F6F3FB' : '#C7A9F1',
                  border: isLeft ? '1px solid rgb(221 211 238)' : '1px solid rgb(121 79 201)'
                }}
                className={classNames('p-2 rounded-3 chat-message', {
                  ' text-white': !isLeft
                })}
              >
                {message.body && (
                  <p
                    className='mb-0'
                    dangerouslySetInnerHTML={{
                      __html: message.body
                    }}
                  />
                )}
                <FalconLightBoxGallery images={message.attachments}>
                  {setImgIndex => (
                    <div className='d-flex gap-2 flex-wrap'>
                      {message.attachments.map((img, index) => {
                        const isImage = ['.png', '.jpg', '.png', 'mpeg', 'jpeg'].some(ext => img.endsWith(ext))

                        return (
                          <>
                            {isImage ? (
                              <div>
                                <Image
                                  bsPrefix
                                  rounded
                                  width={width < 500 ? 180 : 300}
                                  height={width < 500 ? 100 : 200}
                                  className='mb-2 cursor-pointer'
                                  src={img}
                                  alt=''
                                  onClick={() => setImgIndex(index)}
                                />
                              </div>
                            ) : (
                              <div
                                style={{ background: '#C7A9F1' }}
                                className={classNames(
                                  'p-2 rounded-2 d-flex align-items-center gap-1 justify-content-between',
                                  {
                                    'text-white ': isLeft,
                                    'bg-200 text-dark': !isLeft
                                  }
                                )}
                                onClick={() => downloadFile(img)}
                              >
                                Download File <IoMdDownload style={{ cursor: 'pointer' }} size={16} />
                              </div>
                            )}
                          </>
                        )
                      })}
                    </div>
                  )}
                </FalconLightBoxGallery>
              </div>
            ) : (
              <>
                <div
                  style={{
                    backgroundColor: isLeft ? '#F6F3FB' : '#C7A9F1',
                    border: isLeft ? '1px solid rgb(221 211 238)' : '1px solid #C7A9F1'
                  }}
                  className={classNames('p-2 rounded-3 chat-message', {
                    ' text-white': !isLeft
                  })}
                >
                  {(message || message.body) && (
                    <p
                      className='mb-0'
                      dangerouslySetInnerHTML={{
                        __html: message.body
                      }}
                    />
                  )}
                  {message.attachment && (
                    <FalconLightBox image={message.attachment}>
                      <Image
                        rounded
                        src={message.attachment}
                        width={width < 500 ? 180 : 300}
                        height={width < 500 ? 100 : 200}
                        alt=''
                      />
                    </FalconLightBox>
                  )}
                </div>
              </>
            )}
            {/* {isLeft && <ChatMessageOptions />} */}
          </Flex>
          <div
            className={classNames('text-400 fs-11', {
              'text-end': !isLeft
            })}
          >
            <div className='d-flex flex-column'>
              <p className='mb-0' style={{}}>
                {' '}
                {message?.status === 'failed' ? (
                  <span style={{ color: '#E34B4E', fontWeight: '500' }}>Unsuccessful 🚫</span>
                ) : (
                  <span style={{ color: 'rgb(151 151 151)', fontWeight: '500' }}>{message?.status}</span>
                )}{' '}
              </p>
              {dayjs(message.dateAdded).format('YYYY-MM-DD hh:mm a')}
            </div>

            {!isLeft && !!message && !!status && (
              <FontAwesomeIcon
                icon={classNames({
                  check: status === 'seen' || status === 'sent',
                  'check-double': status === 'delivered'
                })}
                className={classNames('ms-2', {
                  'text-success': status === 'seen'
                })}
              />
            )}
          </div>
        </div>
      </div>
    </Flex>
  )
}

export default Message
