import { get, checkUserDetailResponse, put } from '../../utils/constants'
import { ReducerType } from './type'
import { apis } from '../../utils/Apis'

export const getGlobalNotificationAction = () => async dispatch => {
  dispatch({
    type: ReducerType.GLOBAL_NOTIFICATION_ACTION_START
  })
  const res = await get(apis.notificationsGlobalDetail)
  const response = await checkUserDetailResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GLOBAL_NOTIFICATION_SUCCESS,
      payload: response.data?.data
    })
    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.GLOBAL_NOTIFICATION_ERROR,
      payload: response?.errorMessages
    })
  }
}

export const editGlobalNotificationsSettings = async data => {
  const res = await put(apis.notificationsApplyGlobal, data)
  return res
}

export const editNotificationsGlobalUpdate = async data => {
  const res = await put(apis.notificationsGlobalUpdate, data)
  return res
}
