import React, { useEffect } from 'react'
import { Tab } from 'react-bootstrap'
import GroupChatContentBody from './ClientChatContentBody'
import ClMessageTextArea from './ClientMessageTextArea'
import { useDispatch, useSelector } from 'react-redux'
import { getAllGroupMessages } from '../../../../redux/groupChat/action'
import { Decrypt } from 'utils/constants'
import { useParams } from 'react-router-dom'
import { joinRoom, ListionMessage, ErrorMessages, socket } from 'utils/socket'

const GroupChatContent = React.memo(({ activeTab }) => {
  const dispatch = useDispatch()
  let { id } = useParams()
  let decryptedId = Decrypt(id)
  const { userDetails } = useSelector(state => state.login)

  useEffect(() => {
    const handleBeforeUnload = () => {
      dispatch({ type: 'REMOVE_STATES' })
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    if (decryptedId) {
      let userId = userDetails?._id
      dispatch(getAllGroupMessages({ project_id: decryptedId, limit: 20, page: 1, type: 'client' }))
      joinRoom(decryptedId, 'client', userId)
      ErrorMessages(decryptedId, 'client', userId)
    }
  }, [decryptedId, userDetails])
  useEffect(() => {
    if (decryptedId) {
      ListionMessage(dispatch, decryptedId, 'client', activeTab)
    }
  }, [decryptedId])
  return (
    <Tab.Content className='card-chat-content'>
      <Tab.Pane key={0} eventKey={0} className='card-chat-pane'>
        <GroupChatContentBody activeTab={activeTab} />
      </Tab.Pane>
      <ClMessageTextArea />
    </Tab.Content>
  )
})

export default GroupChatContent
