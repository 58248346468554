import React, { useEffect, useState } from 'react'
import ProfileBanner from '../ProfileBanner'
import coverSrc from 'assets/img/generic/4.jpg'
import { Col, Row, Spinner, Card } from 'react-bootstrap'
import ProfileSettings from './ProfileSettings'
import ChangePassword from './ChangePassword'
import { useDispatch, useSelector } from 'react-redux'
import GlobalNotification from './GlobalNotification'
import { getGlobalNotificationAction } from '../../../../redux/setting/action'

const Settings = () => {
  const dispatch = useDispatch()
  const { userDetails, loading } = useSelector(state => state.login)
  const { isGlobalNotification } = useSelector(state => state.settings)

  useEffect(() => {
    dispatch(getGlobalNotificationAction())
  }, [])

  return (
    <>
      <ProfileBanner>
        <ProfileBanner.Header
          coverSrc={coverSrc}
          avatar={
            userDetails
              ? userDetails?.name
                ? userDetails?.name
                : `${userDetails?.firstName} ${userDetails?.lastName}`
              : ''
          }
          className='mb-8'
        />
      </ProfileBanner>
      <Row className='g-3 mb-2'>
        <Col lg={8}>
          {loading ? (
            <Card
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '8px 15px',
                flexWrap: 'wrap',
                gap: '10px',
                height: '350px'
              }}
            >
              <Spinner />
            </Card>
          ) : (
            <ProfileSettings />
          )}
        </Col>
        <Col lg={4}>
          {loading ? (
            <Card
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '8px 15px',
                flexWrap: 'wrap',
                gap: '10px',
                height: '350px'
              }}
            >
              <Spinner />
            </Card>
          ) : (
            <ChangePassword />
          )}
        </Col>
      </Row>
      <Row className=' mb-4'>
        <Col lg={12}>
          {isGlobalNotification ? (
            <Card
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '8px 15px',
                flexWrap: 'wrap',
                gap: '10px',
                height: '400px'
              }}
            >
              <Spinner />
            </Card>
          ) : (
            <GlobalNotification />
          )}
        </Col>
      </Row>
    </>
  )
}

export default Settings
