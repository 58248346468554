import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import NavbarTop from 'components/navbar/top/NavbarTop'

// import ModalAuth from 'components/authentication/modal/ModalAuth';
import { getUserDetail } from '../redux/login/action'
import { getSavedPipeline } from '../redux/calender/action'
import { getAllPipelines } from '../redux/projects/action'
import { getLatestNotifications } from '../redux/notifications/action'
import { useAppContext } from 'providers/AppProvider'
import authConfig from '../utils/authConfig'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { UserDelete, connectSocket, disconnectSoket } from 'utils/socket'
import { RemoveAllTokens } from 'utils/constants'
import { getAllUsers } from '../redux/users/action'
import { toast } from 'react-toastify'
import { ShiftToLogin } from 'utils/constants'
import { getMessagingToken, onMessageListener } from '../firebase/firebase'
import dayjs from 'dayjs'
import { getAllParticularStages } from '../redux/calender/action'

const MainLayout = () => {
  const { hash, pathname } = useLocation()
  const { userDetails } = useSelector(state => state.login)
  const { savedPipleline } = useSelector(state => state.calenders)

  const [deleteValue, setDeletedVal] = useState(null)
  let token = localStorage.getItem(authConfig.storageTokenKeyName)
  // const isChat = pathname.includes('chat');
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    config: { isFluid }
  } = useAppContext()

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element?.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      }
    }, 0)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    let token = localStorage.getItem(authConfig.storageTokenKeyName)

    if (!token) {
      navigate('/mtc/auth/login')
    }
    if (token) {
      getMessagingToken()
      dispatch(getUserDetail())
      dispatch(getAllUsers())
      dispatch(getAllParticularStages())
      dispatch(getSavedPipeline())
      dispatch(getAllPipelines())
    }
  }, [])

  useEffect(() => {
    if (savedPipleline) {
      dispatch(getLatestNotifications({ page: 1, limit: 5, pipelineId: savedPipleline?.pipelineId }))
    }
  }, [savedPipleline])
  useEffect(() => {
    if (token) {
      connectSocket()
    }
  }, [token])

  useEffect(() => {
    if (userDetails?.status == 0) {
      RemoveAllTokens()
    }
  }, [userDetails])

  useEffect(() => {
    UserDelete(setDeletedVal)
  }, [])

  useEffect(() => {
    if (deleteValue) {
      if (deleteValue?.user_id == userDetails?._id) {
        toast.error(deleteValue?.message, {
          position: 'top-right',
          theme: 'colored'
        })
        RemoveAllTokens()
        ShiftToLogin()
      }
    }
  }, [deleteValue])

  const handleRedirect = () => {
    window.location.href = '/app/notifications'
  }

  onMessageListener()
    .then(payload => {
      let newResponse = {
        content: { message: payload?.data?.body, title: payload?.data?.title },
        is_read: 0,
        request_id: payload?.data?.request_id,
        createdAt: dayjs().toISOString(),
        request_type: parseInt(payload?.data?.request_type)
      }
      dispatch({ type: 'GET_PUSH_NOTIFICATION', payload: newResponse })
      toast.info(
        <div>
          <div
            onClick={handleRedirect}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '10px',
              cursor: 'pointer'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              <p style={{ fontWeight: '600' }}>{payload?.notification?.title}</p>
              <p style={{ fontWeight: '500', wordBreak: 'break-all' }}>{payload?.notification?.body}</p>
            </div>
          </div>
        </div>,
        {
          position: 'top-right',
          theme: 'colored'
        }
      )
    })
    .catch(err => console.log('failed: ', err))

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {/* {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical />
      )} */}

      <div className={classNames('content', 'pb-0')}>
        <NavbarTop />
        {/*------ Main Routes ------*/}
        <div className='mt-3'>
          <Outlet />
          {/* {!isKanban && <Footer />} */}
        </div>
      </div>

      {/* <ModalAuth /> */}
    </div>
  )
}

export default MainLayout
