import { checkResponse, get, post, put } from '../../utils/constants'
import { ReducerType } from './type'
import { apis } from '../../utils/Apis'

export const getAllConversations = params => async dispatch => {
  dispatch({
    type: ReducerType.GET_ALL_CONVERSATION_START
  })
  const res = await get(apis.get_all_conversations, params)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_ALL_CONVERSATION,
      payload: response.data?.data
    })

    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.GET_ALL_CONVERSATION_ERROR
    })
  }
}

export const getConvMessages = params => async dispatch => {
  dispatch({
    type: ReducerType.GET_CONVERSATION_MESSAGE_START
  })
  const res = await get(apis.get_conversation_messages, params)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_CONVERSATION_MESSAGE,
      payload: response.data?.data
    })

    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.GET_CONVERSATION_MESSAGE_ERROR
    })
  }
}

export const getOldConvMessages = params => async dispatch => {
  dispatch({
    type: ReducerType.GET_CONVERSATION_MESSAGE_START
  })
  const res = await get(apis.get_conversation_messages, params)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_OLDER_MESSAGES,
      payload: response.data?.data
    })

    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.GET_CONVERSATION_MESSAGE_ERROR
    })
  }
}
///develoer listing

export const getDevConvMessages = params => async dispatch => {
  dispatch({
    type: ReducerType.GET_CONVERSATION_MESSAGE_START
  })
  const res = await get(apis.get_conversation_messages, params)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_DEV_CONVERSATION_MESSAGE,
      payload: response.data?.data
    })

    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.GET_CONVERSATION_MESSAGE_ERROR
    })
  }
}

export const getDevOldConvMessages = params => async dispatch => {
  dispatch({
    type: ReducerType.GET_CONVERSATION_MESSAGE_START
  })
  const res = await get(apis.get_conversation_messages, params)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_DEV_OLDER_MESSAGES,
      payload: response.data?.data
    })

    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.GET_CONVERSATION_MESSAGE_ERROR
    })
  }
}

export const getSMSDetail = params => async dispatch => {
  const res = await get(apis.get_message_detail, params)
  const response = await checkResponse(res)
  if (response?.success) {
    let updatedRes = { ...response.data?.data, status: 'delivered' }
    dispatch({
      type: ReducerType.GET_SMS_DETAIL,
      payload: updatedRes
    })

    return { data: response.data }
  } else {
    console.log('error')
  }
}

export const getEmailDetail = params => async dispatch => {
  dispatch({
    type: ReducerType.GET_EMAIL_DETAIL_STRAT
  })
  const res = await get(apis.get_email_detail, params)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_EMAIL_DETAIL,
      payload: response.data?.data
    })

    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.GET_EMAIL_DETAIL_ERROR
    })
  }
}

export const getEmailDetailUnshift = params => async dispatch => {
  dispatch({
    type: ReducerType.GET_EMAIL_DETAIL_STRAT
  })
  const res = await get(apis.get_email_detail, params)
  const response = await checkResponse(res)
  if (response?.success) {
    dispatch({
      type: ReducerType.GET_EMAIL_DETAIL_UNSHIFT,
      payload: response.data?.data
    })

    return { data: response.data }
  } else {
    dispatch({
      type: ReducerType.GET_EMAIL_DETAIL_ERROR
    })
  }
}

export const UplaodSMSEmailFile = async data => {
  const res = await post(apis.uplaod_sms_email_files, data)
  return res
}
export const sendSms = async data => {
  const res = await post(apis.send_sms, data)
  return res
}
